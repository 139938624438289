import axios from "axios";

const state = {
  selectedRegion: null,
  availableData: [],
  downloads: [],
  isLoading: true,
  refresh: false,
  filter: false,
  processBtn: true,
  virtualStations: [],
  firstSceneDate: null,
  lastSceneDate: null,
  layersCollection: []
};
const mutations = {
  SET_SELECTED_REGION(state, payload) {
    state.selectedRegion = payload;
  },
  SET_DOWNLOADS(state, downloads) {
    state.downloads = downloads;
  },
  SET_AVAILABLE_DATA(state, avData) {
    state.availableData = avData;
  },
  UPDATE_DOWNLOAD_ZIP_FILES(state, payload) {
    const targetDownload = state.downloads.find(
      download => download.id === payload.id
    );
    if (targetDownload) {
      targetDownload.filesData = payload.downloadData;
    }
  },
  SET_IS_LOADING(state, lodingStatus) {
    state.isLoading = lodingStatus;
  },
  SET_REFRESH(state, refreshStatus) {
    state.refresh = refreshStatus;
  },
  SET_FILTER(state, filterStatus) {
    state.filter = filterStatus;
  },
  SET_PROCESS(state, processStatus) {
    state.processBtn = processStatus;
  },
  SET_VIRTUAL_STATIONS(state, payload) {
    state.virtualStations = payload;
  },
  SET_FIRST_DATE(state, fd) {
    state.firstSceneDate = fd;
  },
  SET_LAST_DATE(state, ld) {
    state.lastSceneDate = ld;
  },
  SET_LAYERS_COLLECTION(state, layersCollection) {
    state.layersCollection = layersCollection;
  },
  UPDATE_DOWNLOAD_STATUS(state, payload) {
    const { id, status } = payload;
    const downloadToUpdate = state.downloads.find(
      download => download.id === id
    );
    if (downloadToUpdate) {
      downloadToUpdate.status = status;
    }
  }
};
const actions = {
  updateSelectedRegion({ commit }, payload) {
    commit("SET_SELECTED_REGION", payload);
  },
  updateDownloads({ commit }, downloads) {
    commit("SET_DOWNLOADS", downloads);
  },
  async updateAvailableData({ commit }, avData) {
    commit("SET_AVAILABLE_DATA", avData);
  },

  async fetchDownloadData({ commit }, { id, dataType, region }) {
    let url;
    if (dataType === "Raster data") {
      url = `/userdata/${region}/raster-download/${id}`;
    } else if (dataType === "Time Series data") {
      url = `/userdata/${region}/timeseries-download/${id}`;
    } else {
      throw new Error("Invalid data type");
    }

    try {
      const response = await axios.get(url);
      if (dataType === "Raster data" && response.data && response.data.files) {
        const downloadData = response.data.files.map((file, index) => ({
          name: `${response.data.product}_${index + 1}.zip`,
          url: file.file
        }));
        commit("UPDATE_DOWNLOAD_ZIP_FILES", { id, downloadData });
        return downloadData;
      } else if (
        dataType === "Time Series data" &&
        response.data &&
        response.data.zip_file
      ) {
        const downloadData = [
          {
            name: `Time_series_data_${id}_neue_version.zip`,
            url: response.data.zip_file
          }
        ];
        commit("UPDATE_DOWNLOAD_ZIP_FILES", { id, downloadData });
        return downloadData;
      } else {
        throw new Error("No data found");
      }
    } catch (error) {
      console.error("Error fetching download data:", error.message);
      throw error;
    }
  },
  updateIsLoading({ commit }, lodingStatus) {
    commit("SET_IS_LOADING", lodingStatus);
  },
  updateRefresh({ commit }, refreshStatus) {
    commit("SET_REFRESH", refreshStatus);
  },
  updateFilter({ commit }, filterStatus) {
    commit("SET_FILTER", filterStatus);
  },
  updateProcess({ commit }, processStatus) {
    commit("SET_PROCESS", processStatus);
  },
  updateVirtualStations({ commit }, payload) {
    commit("SET_VIRTUAL_STATIONS", payload);
  },
  updateFirstDate({ commit }, firstDate) {
    commit("SET_FIRST_DATE", firstDate);
  },
  updateLastDate({ commit }, lastDate) {
    commit("SET_LAST_DATE", lastDate);
  },
  updateLayersCollection({ commit }, lc) {
    commit("SET_LAYERS_COLLECTION", lc);
  },
  updateDownloadStatus({ commit, state }, payload) {
    const { id, status } = payload;
    const downloadToUpdate = state.downloads.find(
      download => download.id === id
    );

    if (downloadToUpdate) {
      commit("UPDATE_DOWNLOAD_STATUS", { id, status });
    }
  }
};
const getters = {
  getSelectedRegion: state => state.selectedRegion,
  getDownloads: state => state.downloads,
  getAvData: state => state.availableData,
  getIsLoading: state => state.isLoading,
  getRefresh: state => state.refresh,
  getFilter: state => state.filter,
  getProcess: state => state.processBtn,
  getVirtualStations: state => state.virtualStations
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
