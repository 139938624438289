import MapboxConfig from "@/core/plugins/mapbox";

let mapboxMixin = {
  methods: {
    getMapStyle() {
      return {
        version: 8,
        sources: {
          eomapBasemapSource: {
            type: "raster",
            tiles: [
              `https://api.mapbox.com/styles/v1/eomap/ckq7xvq6s068c17qwcjbmc7wx/tiles/{z}/{x}/{y}?access_token=${MapboxConfig.accessToken}`
            ],
            tileSize: 512,
            attribution:
              "<a href='https://www.mapbox.com/about/maps/'>© Mapbox</a> <a href='http://www.openstreetmap.org/copyright'>© OpenStreetMap</a>"
          },
          eomapBasemapSourceV2: {
            type: "raster",
            tiles: [
              `https://api.mapbox.com/styles/v1/mapbox/streets-v8/tiles/{z}/{x}/{y}?access_token=${MapboxConfig.accessToken}`
            ],
            tileSize: 512,
            attribution:
              "<a href='https://www.mapbox.com/about/maps/'>© Mapbox</a> <a href='http://www.openstreetmap.org/copyright'>© OpenStreetMap</a>"
          },
          satelliteSource: {
            type: "raster",
            tiles: [
              `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${MapboxConfig.accessToken}`
            ],
            tileSize: 512,
            attribution:
              "<a href='https://www.mapbox.com/about/maps/'>© Mapbox</a> <a href='https://www.maxar.com/'>© Maxar</a>"
          },
          indexSource: {
            type: "geojson",
            data: { type: "FeatureCollection", features: [] }
          },
          terrainSource: {
            type: "raster-dem",
            url: "mapbox://mapbox.mapbox-terrain-dem-v1",
            tileSize: 512,
            maxzoom: 14
          }
        },
        layers: [
          {
            id: "basemapEomapLayer",
            type: "raster",
            source: "eomapBasemapSource",
            minzoom: 0,
            maxzoom: 22,
            layout: {
              visibility: "visible"
            }
          },
          {
            id: "basemapEomapLayerV2",
            type: "raster",
            source: "eomapBasemapSourceV2",
            minzoom: 0,
            maxzoom: 22,
            layout: {
              visibility: "none"
            }
          },
          {
            id: "basemapSatelliteLayer",
            type: "raster",
            source: "satelliteSource",
            minzoom: 0,
            maxzoom: 22,
            layout: {
              visibility: "none"
            }
          },
          {
            id: "z-index-1",
            type: "symbol",
            source: "indexSource"
          },
          {
            id: "z-index-2",
            type: "symbol",
            source: "indexSource"
          },
          {
            id: "z-index-3",
            type: "symbol",
            source: "indexSource"
          },
          {
            id: "z-index-4",
            type: "symbol",
            source: "indexSource"
          }
        ],
        glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
        sprite: "mapbox://sprites/mapbox/streets-v8"
      };
    },
    getMapboxLayerName(layerId, type) {
      return {
        source: `app-source-${layerId}-${type}`,
        layer: `app-layer-${layerId}-${type}`
      };
      // return {
      //   source: `${type}Source`,
      //   layer: `${type}Layer`
      // };
    },

    showTerrain(map, layerId, layerStep, elevation = 1.5) {
      map.setLayoutProperty("basemapEomapLayer", "visibility", "none");
      map.setLayoutProperty("basemapEomapLayerV2", "visibility", "none");
      map.setLayoutProperty("basemapSatelliteLayer", "visibility", "none");
      if (this.$appConfig.keycloakClient === "coasts") {
        const { source } = this.getMapboxLayerName(layerId, "raster-dem");
        map.setTerrain({
          source: source,
          exaggeration: elevation
        });
      } else {
        map.setTerrain({
          source: "raster-terrainSource",
          exaggeration: elevation
        });
      }
    },

    hideTerrain(map) {
      if (this.$appConfig.keycloakClient === "coasts") {
        map.setLayoutProperty("basemapSatelliteLayer", "visibility", "visible");
      } else {
        map.setLayoutProperty("basemapEomapLayer", "visibility", "visible");
      }
      map.setTerrain();
    }
  }
};
export default mapboxMixin;
