import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "md",
    defaultSet: "mdi"
  },
  theme: {
    themes: {
      light: {
        primary: "#2c4186", // #E5393
        secondary: "#40ADD5",
        textColor: "#173466",
        background: "#F6F7FB",
        accent: colors.orange
      },
      dark: {
        primary: "#2c4186"
      }
    }
  }
});
