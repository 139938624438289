<template>
  <v-main class="fill-height">
    <v-container>
      <view-header
        :title="$t('regionOverview.myRegions')"
        style="margin-top: 40px;"
      ></view-header>
      <v-row>
        <v-spacer />
        <search-bar @filterRegions="setSearch" v-if="!mapView"></search-bar>
        <v-col class="text-right pt-0 pl-0 pb-4" cols="auto">
          <v-btn text icon @click="toggleMapView('list')">
            <v-icon :color="!mapView ? 'primary' : ''"
              >mdi-format-list-bulleted</v-icon
            >
          </v-btn>
          <v-btn icon @click="toggleMapView('map')">
            <v-icon :color="mapView ? 'primary' : ''">mdi-map</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <v-row class="pt-3">
        <v-col align="center">
          <v-btn
            style="border-style: dashed; margin-bottom: 24px;"
            outlined
            width="100%"
            @click="$router.push('order')"
          >
            + {{ $t("regionOverview.addRegion") }}
          </v-btn>
        </v-col>
      </v-row>
      <div
        class="view-height custom-scrollbar"
        style="overflow-y: auto; overflow-x: hidden;"
        v-if="regionFeatures.length"
      >
        <regions-list
          v-if="!mapView"
          :search="search"
          :region-features="regionFeatures"
          style="padding-right: 12px;"
        ></regions-list>
        <regions-map v-else :region-features="regions"></regions-map>
      </div>
      <add-demo-role-dialog
        v-if="!regionFeatures.length && !loadingRegions"
        :region-features="regionFeatures"
      ></add-demo-role-dialog>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import RegionsList from "@/core/components/regionsOverview/RegionsList.vue";
import RegionsMap from "@/core/components/regionsOverview/RegionsMap.vue";
import ViewHeader from "@/core/components/ViewHeader.vue";
import SearchBar from "@/core/components/SearchBar.vue";
import AddDemoRoleDialog from "@/core/components/regionsOverview/addDemoRoleDialog.vue";

export default {
  name: "Regions",
  components: {
    AddDemoRoleDialog,
    SearchBar,
    ViewHeader,
    RegionsMap,
    RegionsList
  },
  data: () => ({
    dialog: false,
    mapView: false,
    search: "",
    demoDialog: false
  }),
  computed: {
    ...mapState("management", ["regions", "loadingRegions"]),
    ...mapGetters("management", ["regionFeatures", "regionSelection"])
  },
  methods: {
    ...mapActions("management", ["getOrFetchAccessToken"]),
    goToOrder() {
      this.$router.push("Order");
      this.dialog = false;
    },
    toggleMapView(prop) {
      if (prop == "list") {
        this.mapView = false;
      } else {
        this.mapView = true;
      }
    },
    setSearch(value) {
      this.search = value;
    }
  },
  watch: {
    loadingRegions: {
      handler() {
        this.loading = false;
      },
      immediate: true
    }
  },
  created() {
    this.getOrFetchAccessToken();
  }
};
</script>

<style>
.custom-scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #e9eff5;
  border-radius: 8px;
}

/* Weitere Anpassungen für andere Browser (Firefox, Edge, usw.) */
/* Beispiel für Firefox */
.custom-scrollbar {
  scrollbar-width: thin; /* Breite des Scrollbalkens in Firefox */
  scrollbar-color: #888 transparent; /* Farbe des Scrollbalken-Thumb in Firefox */
}
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Hintergrundfarbe des Track (Bahn des Scrollbalkens) */
}

.view-height {
  height: 60vh;
}

.button-block .sqs-block-button-element {
  color: black !important;
  border: 2.22px dashed #000000 !important;
  padding: 10px 44px !important;
}
</style>
