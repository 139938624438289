import Vue from "vue";
import { mapActions } from "vuex";

let serviceSelectionMixin = {
  data: () => ({
    featureObjects: {
      mapView: {
        icon: "mdi-map-outline",
        label: "toolBarFeatures.mapView",
        route: {
          name: "MapView",
          params: true
        }
      },
      dashboard: {
        icon: "query_stats",
        googleIcon: "query_stats",
        label: "toolBarFeatures.monitoring",
        route: {
          name: "MonitoringDashboard",
          params: true
        }
      },
      baseline: {
        icon: "bar_chart",
        googleIcon: "bar_chart",
        label: "toolBarFeatures.baseline",
        route: {
          name: "BaselineDashboard",
          params: true
        }
      },
      thresholds: {
        icon: "data_thresholding",
        googleIcon: "data_thresholding",
        label: "toolBarFeatures.thresholds",
        route: {
          name: "GlobalsThresholds",
          params: true
        }
      },
      download: {
        icon: "mdi-tray-arrow-down",
        googleIcon: "file_download",
        label: "toolBarFeatures.downloads",
        route: { name: "Download", params: true }
      },
      insitu: {
        icon: "mdi-flask",
        googleIcon: "science",
        label: "toolBarFeatures.insituData",
        route: { name: "InSituData", params: true }
      },
      sediment: {
        icon: "mdi-water-opacity",
        googleIcon: "opacity",
        label: "toolBarFeatures.sediment",
        route: { name: "Sediment", params: true }
      },
      orderHistory: {
        icon: "mdi-file-search-outline",
        label: "toolBarFeatures.orderHistory",
        route: {
          name: "OrderHistory",
          params: true
        }
      },
      addOrder: {
        icon: "mdi-shopping-outline",
        label: "toolBarFeatures.addOrder",
        route: {
          name: "RegionOrder",
          params: true
        }
      },
      deleteRegion: {
        icon: "mdi-delete-forever",
        googleIcon: "delete-forever",
        label: "toolBarFeatures.delete",
        route: {
          name: "",
          params: false
        }
      },
      compareMap: {
        icon: "mdi-compare",
        googleIcon: "compare",
        label: "toolBarFeatures.compare",
        route: {
          name: "Compare",
          params: true
        }
      },
      coastAlerts: {
        icon: "mdi-alert-outline",
        googleIcon: "notifications_active",
        label: "toolBarFeatures.alerts",
        route: {
          name: "CoastsAlerts",
          params: true
        }
      },
      scenarios: {
        icon: "mdi-trending-up",
        googleIcon: "trending-up",
        label: "toolBarFeatures.scenarios",
        route: {
          name: "Forecast",
          params: true
        }
      },
      flagging: {
        icon: "mdi-box-cutter",
        label: "toolBarFeatures.flagging",
        route: {
          name: "Flagging",
          params: true
        }
      }
    }
  }),
  computed: {
    roles() {
      const client = this.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles;
      return roles;
    },
    regionOwner() {
      const hasOwner = this.region.properties.owner;
      return hasOwner;
    },
    hasEomapRole() {
      const hasEomapRole = Vue.prototype.$keycloak.realmAccess?.roles.includes(
        "eomap"
      );
      return hasEomapRole;
    }
  },
  methods: {
    ...mapActions("management", ["getOrFetchRegion"]),
    getShowFeature(region, feature) {
      let renameMonitoring = "";
      if (feature === "monitoring") {
        renameMonitoring = "dashboard";
      } else renameMonitoring = feature;
      // TODO: this solution is for transition phase, to make sure every user have access like in the past
      if (
        this.hasEomapRole ||
        (this.roles && this.roles.includes(renameMonitoring))
      ) {
        return true;
      } else if (region.properties?.services?.length) {
        return region.properties.services.includes("premium");
      }
      return false;
    },
    async getFeatures(regionId, region = null) {
      if (!region) {
        region = await this.getOrFetchRegion(regionId);
      }
      const client = Vue.prototype.$appConfig.keycloakClient;
      let coreFeatures = [];
      let addFeatures = [];
      if (client == "eoportal") {
        coreFeatures = ["mapView", "dashboard", "baseline"];
        addFeatures = [
          "thresholds",
          "insitu",
          "download",
          "orderHistory",
          "addOrder",
          "deleteRegion",
          "flagging"
        ];
      } else if (client == "hypos") {
        coreFeatures = ["mapView", "baseline", "insitu"];
        addFeatures = ["sediment", "download", "flagging"];
      } else if (client == "xylem") {
        coreFeatures = ["mapView", "dashboard", "baseline"];
        addFeatures = [
          "thresholds",
          "insitu",
          "download",
          "orderHistory",
          "addOrder",
          "deleteRegion",
          "flagging"
        ];
      } else if (client == "coasts") {
        coreFeatures = ["mapView", "compareMap", "scenarios", "coastAlerts"];
        addFeatures = [];
      }
      let coreFeatureObjects = this.filterFeatureObjects(region, coreFeatures);
      coreFeatureObjects = this.checkAvailability(
        region,
        coreFeatures,
        coreFeatureObjects
      );
      let addFeatureObjects = this.filterFeatureObjects(region, addFeatures);
      return {
        coreFeatures: coreFeatureObjects,
        addFeatures: addFeatureObjects
      };
    },
    checkAvailability(region, feature, object) {
      const client = this.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles || [];
      object.available = true;
      if (feature === "baseline" && !this.getShowFeature(region, "baseline")) {
        object.available = false;
      } else if (
        feature === "dashboard" &&
        !this.getShowFeature(region, "monitoring")
      ) {
        object.available = false;
      } else if (
        feature === "addOrder" &&
        !roles.includes("admin") &&
        !this.regionOwner
      ) {
        object.available = false;
      } else if (
        (feature === "flagging" &&
          !roles.includes("admin") &&
          !this.regionOwner) ||
        (feature === "flagging" &&
          !roles.includes("admin") &&
          !this.hasEomapRole)
      ) {
        object.available = false;
      } else if (
        feature === "deleteRegion" &&
        !roles.includes("admin") &&
        !this.regionOwner
      ) {
        object.available = false;
      } else if (
        feature === "thresholds" &&
        !this.getShowFeature(region, "baseline") &&
        !this.getShowFeature(region, "monitoring")
      ) {
        object.available = false;
      }
      return object;
    },
    filterFeatureObjects(region, featureList) {
      let featureObjects = [];
      featureList.forEach(feature => {
        let object = JSON.parse(JSON.stringify(this.featureObjects[feature]));
        if (object?.route?.params) {
          object.route.params = { regionId: region.id };
        }
        object = this.checkAvailability(region, feature, object);
        featureObjects.push(object);
      });
      return featureObjects;
    }
  }
};

export default serviceSelectionMixin;
