const state = {
  activeRasterLayer: {
    layerId: "",
    product: "",
    title: "",
    unit: "",
    datetime: "",
    sensor: "",
    resolution: 0,
    colormap: ""
  },
  rasterLayer: [],
  layerTimesteps: [],
  showMetaDataWindow: true,
  selectedStep: "",
  selectedPlotStep: "",
  tempResLayerTimesteps: [],
  filteredDatetimeSelection: []
};

const actions = {
  setActiveLayer({ commit }, activeLayer) {
    commit("SET_ACTIVE_LAYER", activeLayer);
  },
  setRasterLayer({ commit }, rasterLayer) {
    commit("SET_RASTER_LAYER", rasterLayer);
  },
  setLayerTimesteps({ commit }, timesteps) {
    commit("SET_LAYER_TIMESTEPS", timesteps);
  },
  setShowMetaDataWindow({ commit }, bool) {
    commit("SET_SHOW_META_DATA_WINDOW", bool);
  },
  setTempResLayerTimesteps({ commit }, timesteps) {
    commit("SET_TEMP_RES_LAYER_TIMESTEPS", timesteps);
  },
  setSelectedStep({ commit }, step) {
    commit("SET_SELECTED_STEP", step);
  },
  setSelectedPlotStep({ commit }, step) {
    commit("SET_SELECTED_PLOT_STEP", step);
  },
  setFilteredDatetimeSelection({ commit }, step) {
    commit("SET_FILTERED_DATETIME_SELECTION", step);
  }
};

const mutations = {
  SET_ACTIVE_LAYER(state, data) {
    state.activeRasterLayer = data;
  },
  SET_RASTER_LAYER(state, data) {
    state.rasterLayer = data;
  },
  SET_LAYER_TIMESTEPS(state, data) {
    state.layerTimesteps = data;
  },
  SET_SHOW_META_DATA_WINDOW(state, data) {
    state.showMetaDataWindow = data;
  },
  SET_TEMP_RES_LAYER_TIMESTEPS(state, data) {
    state.tempResLayerTimesteps = data;
  },
  SET_SELECTED_STEP(state, data) {
    state.selectedStep = data;
  },
  SET_SELECTED_PLOT_STEP(state, data) {
    state.selectedPlotStep = data;
  },
  SET_FILTERED_DATETIME_SELECTION(state, data) {
    state.filteredDatetimeSelection = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
