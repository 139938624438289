const state = {
  selectedTopic: ""
};

const actions = {};

const mutations = {
  SET_SELECTED_TOPIC(state, value) {
    state.selectedTopic = value;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
