<template>
  <div>
    <v-row class="hidden-sm-and-down">
      <v-col cols="auto"
        ><span class="heading-1">{{ title }}</span>
      </v-col>
      <v-col class="align-self-end pb-6"
        ><span class="caption-1">{{ subTitle }}</span></v-col
      >
    </v-row>
    <div class="hidden-md-and-up">
      <v-row>
        <v-col cols="auto"
          ><span class="heading-1">{{ title }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="  align-self-end"
          ><span class="caption-1">{{ subTitle }}</span></v-col
        >
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewHeader",
  props: {
    title: String,
    subTitle: String
  }
};
</script>

<style scoped></style>
