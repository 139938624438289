import axios from "axios";
import Url from "@/core/services/url.services";

const state = {
  correlationFormulaItems: [],
  loadCorrelationFormulaItems: false,
  correlationDischargeFormulaItems: [],
  loadCorrelationDischargeFormulaItems: false,
  sedimentStations: [],
  loadSedimentStations: false,
  activeLayer1: {},
  activeLayer2: {}
};

const actions = {
  setActiveLayer1({ commit }, activeLayer) {
    commit("SET_ACTIVE_LAYER1", activeLayer);
  },
  setActiveLayer2({ commit }, activeLayer) {
    commit("SET_ACTIVE_LAYER2", activeLayer);
  },
  async fetchCorrelationFormulas({ commit }) {
    commit("FETCH_CORRELATION_LOADING", true);
    const response = await axios.get(Url.correlation);
    commit("SET_CORRELATION_FORMULAS", response.data);
    commit("FETCH_CORRELATION_LOADING", false);
  },
  async fetchCorrelationDischargeFormulas({ commit }) {
    commit("FETCH_CORRELATION_DISCHARGE_LOADING", true);
    const response = await axios.get(Url.correlationDischarge);
    commit("SET_CORRELATION_DISCHARGE_FORMULAS", response.data);
    commit("FETCH_CORRELATION_DISCHARGE_LOADING", false);
  },
  async fetchSedimentStations({ commit, rootState }) {
    commit("FETCH_SEDIMENT_LOADING", true);
    const regionId = rootState.management.activeRegion.id;
    const response = await axios.get(Url.sedimentStations(regionId));
    commit("SET_SEDIMENT_STATIONS", response.data);
    commit("FETCH_SEDIMENT_LOADING", false);
  }
};

const mutations = {
  SET_ACTIVE_LAYER1(state, data) {
    state.activeLayer1 = data;
  },
  SET_ACTIVE_LAYER2(state, data) {
    state.activeLayer2 = data;
  },
  SET_CORRELATION_FORMULAS(state, data) {
    state.correlationFormulaItems = data;
  },
  SET_CORRELATION_DISCHARGE_FORMULAS(state, data) {
    state.correlationDischargeFormulaItems = data;
  },
  FETCH_CORRELATION_LOADING(state, data) {
    state.loadCorrelationFormulaItems = data;
  },
  FETCH_CORRELATION_DISCHARGE_LOADING(state, data) {
    state.loadCorrelationDischargeFormulaItems = data;
  },
  SET_SEDIMENT_STATIONS(state, data) {
    state.sedimentStations = data;
  },
  FETCH_SEDIMENT_LOADING(state, data) {
    state.loadSedimentStations = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
