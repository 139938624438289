<template>
  <section>
    <v-container>
      <v-row>
        <v-col md="6" cols="12" class="text-center">
          <div class="text-h4">{{ $t("whatWeDo.title") }}</div>
          <p class="my-8 text-body-1">{{ $t(infosTexts.part1) }}</p>
          <p class="my-6 text-body-1">{{ $t(infosTexts.part2) }}</p>
          <p class="my-6 text-body-1">{{ $t(infosTexts.part3) }}</p>
        </v-col>
        <v-col md="6" cols="12" class="text-center">
          <object
            :data="image.path"
            type="image/svg+xml"
            height="300"
            width="100%"
          ></object>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "WhatWeDoSection",
  props: {
    client: {},
    contentInfos: {}
  },
  computed: {
    infosTexts() {
      return this.contentInfos.whatDoWeDo.text;
    },
    image() {
      return this.contentInfos.whatDoWeDo.image;
    }
  }
};
</script>

<style scoped>
section {
  background-color: white;
  padding: 124px 0;
}
</style>
