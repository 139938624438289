const state = {
  profile: null,
  profileLayer: false,
  showProfilePlot: false,
  profileTimesteps: [],
  parameter: {},
  site: {},
  showStationPlot: false
};

const actions = {
  setShowProfilePlot({ commit }, value) {
    commit("SET_SHOW_PROFILE_PLOT", value);
  },
  setProfile({ commit }, value) {
    commit("SET_PROFILE", value);
  },
  setProfileLayer({ commit }, value) {
    commit("SET_PROFILE_LAYER", value);
  },
  setProfileTimesteps({ commit }, timesteps) {
    commit("SET_PROFILE_TIMESTEPS", timesteps);
  },
  setSite({ commit }, value) {
    commit("SET_SITE", value);
  },
  setParameter({ commit }, value) {
    commit("SET_PARAMETER", value);
  },
  setShowStationPlot({ commit }, value) {
    commit("SET_SHOW_STATION_PLOT", value);
  }
};

const mutations = {
  SET_SHOW_PROFILE_PLOT(state, value) {
    state.showProfilePlot = value;
  },
  SET_PROFILE(state, value) {
    state.profile = value;
  },
  SET_PROFILE_LAYER(state, value) {
    state.profileLayer = value;
  },
  SET_PROFILE_TIMESTEPS(state, data) {
    state.profileTimesteps = data;
  },
  SET_SITE(state, value) {
    state.site = value;
  },
  SET_PARAMETER(state, value) {
    state.parameter = value;
  },
  SET_SHOW_STATION_PLOT(state, value) {
    state.showStationPlot = value;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
