var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticStyle:{"z-index":"1000"},attrs:{"width":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ? '95vw' : '75vw'},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"fill-height water",attrs:{"height":"90vh"}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"text-h6",staticStyle:{"color":"#173466"}},[_vm._v("Support Center")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","pa-1":"","fill-height":""}},[_c('v-flex',{class:{
              xs12: true,
              sm3: !_vm.$vuetify.breakpoint.xs && !_vm.$vuetify.breakpoint.sm,
              sm1: _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm,
              'pa-2': true
            }}),_c('v-flex',{class:{
              xs12: true,
              sm9: !_vm.$vuetify.breakpoint.xs && !_vm.$vuetify.breakpoint.sm,
              sm11: _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm,
              'pb-2': true
            }},[_c('span',{staticClass:"pl-5"},[_vm._v("Hey, "+_vm._s(this.userInfo[1])),_c('v-icon',{staticClass:"pl-2",attrs:{"color":"#039BE5"}},[_vm._v("mdi-hand-wave")])],1)])],1),_c('v-layout',{attrs:{"row":"","pa-1":"","fill-height":""}},[_c('v-flex',{class:{
              xs12: true,
              sm3: !_vm.$vuetify.breakpoint.xs && !_vm.$vuetify.breakpoint.sm,
              sm1: _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm,
              'pa-2': true
            },staticStyle:{"border-right":"1px solid #000"}},[_c('SupportCenterSidebar',{on:{"change-view":_vm.handleChangeView}})],1),_c('v-flex',{class:{
              xs12: true,
              sm9: !_vm.$vuetify.breakpoint.xs && !_vm.$vuetify.breakpoint.sm,
              sm11: _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm,
              'pa-2': true,
              'fixed-height': true,
              'scrollable-container': true
            },style:(_vm.currentView === 'SubscriptionTable'
                ? { backgroundColor: '#f0f2f4' }
                : { backgroundColor: 'white' })},[_c(_vm.currentView,_vm._b({tag:"component"},'component',_vm.additionalProps,false))],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }