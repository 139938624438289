import AppConfig from "@/app/config";

const AppUtils = {
  getSubdomain: () => {
    const hostname = window.location.hostname;
    const hostnameParts = hostname.split(".");
    let subdomain;
    if (hostnameParts[0] === "www") {
      subdomain = hostnameParts[1];
    } else {
      subdomain = hostnameParts[0];
    }
    return subdomain;
  },
  checkSubdomain: (subdomain, env) => {
    const apps = Object.keys(AppConfig[env]);
    return apps.includes(subdomain);
  },
  getConfig: () => {
    let subdomain;
    let env;
    if (process.env.VUE_APP_PRODUCTION === "true") {
      env = "prod";
      subdomain = AppUtils.getSubdomain();
    } else {
      env = "dev";
      subdomain = process.env.VUE_APP_DEV_SUBDOMAIN;
    }

    const validSubdomain = AppUtils.checkSubdomain(subdomain, env);

    if (validSubdomain) {
      return AppConfig[env][subdomain];
    } else {
      window.location.replace(process.env.VUE_APP_FALLBACK_URL);
    }
  }
};

export default AppUtils;
