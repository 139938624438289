var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{class:{ 'd-flex flex-row': _vm.isLargeScreen, '': !_vm.isLargeScreen },staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"overview-map",style:({
      width: _vm.isLargeScreen ? '30%' : '100%',
      'max-width': _vm.isLargeScreen ? '300px' : ''
    }),attrs:{"id":_vm.regionMapId},on:{"click":function($event){return _vm.$router.push({
        name: 'MapView',
        params: { regionId: _vm.region.id }
      })}}}),_c('v-card-title',{staticClass:"d-flex flex-column align-start",style:({ width: _vm.isLargeScreen ? '30%' : '100%' })},[_c('div',{staticClass:"pb-1"},[_vm._v(" "+_vm._s(_vm.getRegionName(_vm.region))+" ")]),_c('v-card-subtitle',{staticClass:"pl-0 py-1"},[_vm._v(" "+_vm._s(_vm.$t("regionOverview.area"))+": "+_vm._s(_vm.area)+" km"),_c('sup',[_vm._v("2")])]),(_vm.details.dateRange)?_c('v-card-subtitle',{staticClass:"pl-0 py-1"},[_vm._v(" "+_vm._s(_vm.$t("regionOverview.dataRange"))+": "+_vm._s(_vm.details.dateRange.first_step.split("T")[0])+" "+_vm._s(_vm.$t("regionOverview.to"))+" "+_vm._s(_vm.details.dateRange.last_step.split("T")[0])+" ")]):_vm._e()],1),_c('v-spacer'),_c('v-card-actions',[_vm._l((_vm.features.coreFeatures),function(feature){return _c('div',{key:feature.label},[(feature.available)?_c('v-btn',{staticClass:"mx-2",style:({
          'background-color':
            feature.route.name == 'Visualize' ? '#40ADD5' : '#2c4186'
        }),attrs:{"outlined":"","rounded":"","dense":"","small":"","color":"white","to":feature.route}},[_vm._v(" "+_vm._s(_vm.$t(`${feature.label}`)))]):_vm._e()],1)}),(_vm.features.addFeatures)?_c('features-menu',{attrs:{"features":_vm.features.addFeatures,"region":_vm.region,"place":'regionCard'}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }