import Vue from "vue";
import VueRouter from "vue-router";
import WelcomeLogin from "@/core/views/WelcomePage.vue";

import RegionsOverview from "@/core/views/RegionsOverviewView";
// import { trackUrl } from "@/core/plugins/analytics";
import { routeGuard, routeGuardAdmin } from "@/core/plugins/routes";

Vue.use(VueRouter);

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: { name: "Regions" }
    },
    {
      path: "/welcome",
      name: "Welcome",
      component: WelcomeLogin,
      meta: {
        onlyWhenLoggedOut: true,
        hideBar: true
      }
    },
    {
      path: "/regions",
      name: "Regions",
      components: {
        default: RegionsOverview
      },
      meta: {
        showHomeBtn: true,
        showToolbar: false
      }
    },
    {
      path: "/order",
      name: "Order",
      components: {
        default: () => import("@/core/views/OrderRegionView.vue")
      }
    },
    {
      path: "/account-details",
      name: "AccountDetails",
      components: {
        default: () => import("@/core/views/AccountDetails.vue")
      }
    },
    {
      path: "/regions/:regionId/region-order",
      name: "RegionOrder",
      components: {
        default: () => import("@/core/views/OrderRegionView.vue")
      }
    },
    {
      path: "/regions/:regionId",
      name: "SelectedRegion",
      redirect: () => ({
        name: "MapView"
      })
    },
    {
      path: "/regions/:regionId/mapview",
      name: "MapView",
      components: {
        default: () => import("@/core/views/MapView.vue"),
        menu: () => import("@/core/components/menu/MapViewMenu.vue"),
        coastMenu: () => import("@/core/components/menu/VisualizeCoastMenu")
      },
      meta: {
        showToolbar: true
      }
    },
    {
      path: "/regions/:regionId/monitoring",
      name: "MonitoringDashboard",
      components: {
        default: () => import("@/core/views/MonitoringDashboard.vue")
      },
      meta: {
        showToolbar: true,
        service: "monitoring"
      }
    },
    {
      path: "/regions/:regionId/monitoring/:analysisGeometry",
      name: "MonitoringDetails",
      components: {
        default: () => import("@/core/views/MonitoringDetails.vue")
      },
      meta: {
        showToolbar: true,
        service: "monitoring"
      }
    },
    {
      path: "/regions/:regionId/thresholds",
      name: "GlobalsThresholds",
      components: {
        default: () => import("@/core/views/ThresholdsSetttingsView.vue")
      },
      meta: {
        showToolbar: true
      }
    },
    {
      path: "/regions/:regionId/compare",
      name: "Compare",
      components: {
        default: () => import("@/core/views/Compare"),
        coastMenu: () => import("@/core/components/menu/ChangeMapMenu.vue")
      },
      meta: {
        showToolbar: true
      }
    },
    {
      path: "/regions/:regionId/sediment",
      name: "Sediment",
      components: {
        default: () => import("@/core/views/Sediment")
      },
      meta: {
        showToolbar: true
      }
    },
    {
      path: "/regions/:regionId/sediment/results",
      name: "SedimentResults",
      components: {
        default: () => import("@/core/views/SedimentResults.vue"),
        menu: () => import("@/core/components/menu/SedimentResultsMenu.vue")
      },
      meta: {
        showToolbar: true
      }
    },
    {
      path: "/regions/:regionId/forecast",
      name: "Forecast",
      components: {
        default: () => import("@/core/views/ForecastView.vue"),
        coastMenu: () => import("@/core/components/menu/ForecastMenu.vue")
      },
      meta: {
        showToolbar: true
      }
    },
    {
      path: "/regions/:regionId/orders",
      name: "OrderHistory",
      components: {
        default: () => import("@/core/views/OrderHistory.vue")
      },
      meta: {
        showToolbar: true
      }
    },
    {
      path: "/regions/:regionId/orders/:orderId/missions",
      name: "Missions",
      components: {
        default: () => import("@/core/views/Missions.vue")
      },
      meta: {
        showToolbar: true
      }
    },
    {
      path: "/regions/:regionId/baseline",
      name: "BaselineDashboard",
      components: {
        default: () => import("@/core/views/BaselineDashboard.vue")
      },
      meta: {
        showToolbar: true,
        service: "baseline"
      }
    },
    {
      path: "/regions/:regionId/baseline/compare",
      name: "BaselineCompare",
      components: {
        default: () => import("@/core/views/BaselineCompareDetails.vue")
      },
      meta: {
        showToolbar: true,
        service: "baseline"
      }
    },
    {
      path: "/regions/:regionId/baseline/:stationId",
      name: "BaselineSingle",
      components: {
        default: () => import("@/core/views/BaselineSingleDetails.vue")
      },
      meta: {
        showToolbar: true,
        service: "baseline"
      }
    },
    {
      path: "/regions/:regionId/download",
      name: "Download",
      components: {
        default: () => import("@/core/views/DownloadView.vue")
      },
      meta: {
        showToolbar: true
      }
    },
    {
      path: "/regions/:regionId/alerts",
      name: "CoastsAlerts",
      components: {
        default: () => import("@/core/views/CoastsAlerts.vue")
      },
      meta: {
        showToolbar: true
      }
    },
    {
      path: "/regions/:regionId/flagging",
      name: "Flagging",
      components: {
        default: () => import("@/core/views/FlaggingView.vue")
      },
      meta: {
        showToolbar: true,
        service: "flagging"
      }
    },
    {
      path: "/regions/:regionId/inSituData",
      name: "InSituData",
      components: {
        default: () => import("@/core/views/InSituDataOverview.vue")
      },
      meta: {
        showToolbar: true
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  const appIsPublic = Vue.prototype.$appConfig.public;
  const userIsAuthenticated = Vue.prototype.$keycloak.authenticated;

  //trackUrl(to);

  if (to.name === "Welcome" && userIsAuthenticated) {
    next({ name: "Regions" });
  } else if (to.name === "Welcome") {
    next();
  }

  if (userIsAuthenticated || appIsPublic) {
    if (to.meta?.service) {
      if (to.meta?.service === "flagging") {
        routeGuardAdmin(next, to);
      } else {
        routeGuard(next, to);
      }
    } else {
      next();
    }
  } else {
    next({ name: "Welcome" });
  }
});

export default router;
