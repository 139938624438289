import axios from "axios";
import bbox from "@turf/bbox";
import Vue from "vue";

/***
 * For real use case set regions and active Region to null and activate dispacth fetch regions line 113
 */
const state = {
  accessToken: "",
  regions: {
    features: [],
    type: "FeatureCollection"
  },
  loadingRegions: true,
  region: {},
  sessionPolygon: {},
  tempResolution: "all",
  aggregationStep: "0",
  ui_language: "en",
  languages_dict: [
    { value: "de", text: "German" },
    { value: "en", text: "English" },
    { value: "it", text: "Italian" },
    { value: "pt", text: "Portuguese" },
    { value: "fr", text: "French" }
  ]
};

const getters = {
  regionServices: state => {
    return state.region.properties.services;
  },
  regionFeatures: state => {
    const sortedRegions = state.regions.features;
    return sortedRegions;
  },
  showRegionLayer: state => {
    return !!state.regions && !state.activeRegion;
  },
  regionSelection: state => {
    return state.regions.features.map(feature => {
      let text = feature.properties.name;
      if (feature.properties.country_code != undefined) {
        text = `${feature.properties.name} - ${feature.properties.country_code}`;
      }
      return {
        text: text,
        value: feature.id
      };
    });
  },
  regionSelected: state => {
    const activeRegion = state.activeRegion;
    const properties = activeRegion.properties;
    let text = properties.name;
    if (properties.country_code != undefined) {
      text = `${properties.name} - ${properties.country_code}`;
    }
    return {
      value: activeRegion.id,
      text: text
    };
  },
  showRegionSelectionSmall: () => {
    return true;
  },
  activeRegion: (state, getters, rootState) => {
    const regionId = parseInt(rootState.route.params?.regionId);
    if (regionId) {
      return state.regions.features.find(feature => feature.id === regionId);
    }
    return null;
  },
  activeRegionBbox: (state, getters) => {
    if (getters.activeRegion) {
      return bbox(getters.activeRegion);
    }
    return null;
  }
};

const actions = {
  async fetchAccessToken({ commit }) {
    const response = await Vue.prototype.$rastless.get("/access-token", {
      headers: { Authorization: `Bearer ${Vue.prototype.$keycloak.token}` }
    });
    commit("SET_ACCESS_TOKEN", response.data.accessToken);

    return response.data.accessToken;
  },
  async getOrFetchAccessToken({ dispatch, state }) {
    if (state.accessToken) {
      return state.accessToken;
    } else {
      return await dispatch("fetchAccessToken");
    }
  },
  async fetchRegions({ commit }) {
    try {
      const client = Vue.prototype.$appConfig.keycloakClient;
      const response = await axios.get(`/management/${client}/regions/`);
      const featureCollection = response.data;
      if (featureCollection.features.length) {
        featureCollection.features.sort((a, b) =>
          a.properties.name
            .toUpperCase()
            .localeCompare(b.properties.name.toUpperCase())
        );
      }
      commit("SET_REGIONS", featureCollection);
      return featureCollection;
    } catch (err) {
      console.log(err);
    }
  },
  async fetchRegion({ commit }, regionId) {
    try {
      const client = Vue.prototype.$appConfig.keycloakClient;
      const response = await axios.get(
        `/management/${client}/regions/${regionId}`
      );
      const region = response.data;
      commit("SET_REGION", region);
      return region;
    } catch (err) {
      console.log(err);
    }
  },
  async getOrFetchRegions({ dispatch, state }) {
    if (state.regions.features.length) {
      return state.regions;
    } else {
      const regions = await dispatch("fetchRegions");
      dispatch("setLoading", false);
      return regions;
    }
  },
  async getOrFetchRegion({ dispatch, state }, regionId) {
    if (state.region?.id === regionId) {
      return state.region;
    } else {
      return await dispatch("fetchRegion", regionId);
    }
  },
  async setActiveRegion({ dispatch, commit }, feature) {
    if (feature && state.activeRegion && feature.id === state.activeRegion.id) {
      return;
    }
    if (feature) {
      commit("SET_ACTIVE_REGION", null);
      await Promise.all([
        dispatch("vector/fetchVectorLayer", feature.id, { root: true })
      ]);
    }
    commit("SET_ACTIVE_REGION", feature);
    await dispatch("dashboard/setAllData", false, { root: true });
    await dispatch("dashboard/setSelectedStartDate", null, { root: true });
    await dispatch("dashboard/setSelectedEndDate", null, { root: true });
  },
  async fetchUserProfile({ commit }) {
    try {
      const response = await axios.get(`/management/userprofile/`);
      commit("SET_USER_PROFILE_LANGUAGE", response.data.ui_language);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  async updateProfile({ commit }, ui_language) {
    try {
      await axios.post(`/management/userprofile/`, {
        ui_language: ui_language
      });
      commit("SET_USER_PROFILE_LANGUAGE", ui_language);
    } catch (err) {
      console.log(err);
    }
  },
  updateUserProfileLanguage({ dispatch }, ui_language) {
    dispatch("updateProfile", ui_language);
  },
  updateTempResolution({ commit }, tempResolution) {
    commit("SET_TEMP_RESOLUTION", tempResolution);
  },
  updateAggregationStep({ commit }, aggregationStep) {
    commit("SET_AGGREGATION_STEP", aggregationStep);
  },
  setSessionPolygon({ commit }, feature) {
    commit("SET_SESSION_POLYGON", feature);
  },
  setLoading({ commit }, bool) {
    commit("SET_LOADING", bool);
  }
};

const mutations = {
  SET_REGION(state, data) {
    state.region = data;
  },
  SET_REGIONS(state, data) {
    state.regions = null; // regions will be completely reloaded
    state.regions = data;
  },
  SET_ACTIVE_REGION(state, data) {
    state.activeRegion = data;
  },
  SET_USER_PROFILE_LANGUAGE(state, ui_language) {
    state.ui_language = ui_language;
  },
  SET_ACCESS_TOKEN(state, token) {
    state.accessToken = token;
  },
  SET_TEMP_RESOLUTION(state, tempResolution) {
    state.tempResolution = tempResolution;
  },
  SET_AGGREGATION_STEP(state, aggregationStep) {
    state.aggregationStep = aggregationStep;
  },
  SET_SESSION_POLYGON(state, feature) {
    state.sessionPolygon = feature;
  },
  SET_LOADING(state, bool) {
    state.loadingRegions = bool;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
