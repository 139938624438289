import Vue from "vue";

const Url = {
  client: () => {
    return Vue.prototype.$appConfig.keycloakClient;
  },
  rasterSingleValue: ({ lat, lon, datetime, layerName }) => {
    return `/raster/${Url.client()}/wcps/point/${lon}/${lat}/${datetime}?layers=${layerName}`;
  },
  rasterTimeseries: ({ lat, lon, layerName }) => {
    return `/raster/${Url.client()}/wcps/point/${lon}/${lat}/?layers=${layerName}`;
  },
  rasterPolygonValue: (datetime, layerName) => {
    return `/raster/${Url.client()}/wcps/polygon/${datetime}?layers=${layerName}`;
  },
  vectorTimeseries: ({
    geometryId,
    property = "",
    startDate = "",
    endDate = ""
  }) => {
    if (endDate.length === 10) {
      endDate += "T23:59:59";
    }
    return `/vector/${Url.client()}/insitu/${geometryId}/?property=${property}&start_date=${startDate}&end_date=${endDate}`;
  },
  rasterPolygonTimeseries: layerName => {
    return `/raster/${Url.client()}/wcps/polygon/?layers=${layerName}`;
  },
  externalWms: url => {
    return `/external/wms/?url=${url}`;
  },
  uploadVectorData: (id = null) => {
    let url = "/vector/station-data/";
    if (id) {
      url += `${id}/`;
    }
    return url;
  },
  vectorLayer: () => {
    return `/vector/${Url.client()}/layers/`;
  },
  components: () => {
    return `/management/${Url.client()}/components/`;
  },
  geometryData: layerId => {
    return `/vector/${Url.client()}/layers/${layerId}/geometry/`;
  },
  calculateCorrelation: "/analyse/correlations/calculate/",
  csvCorrelation: "/analyse/correlations/data-points/",
  correlation: "/analyse/correlations/",
  correlationDischarge: "/analyse/correlations-discharge/",
  calculateCorrelationEoDischarge:
    "/analyse/correlations-discharge/calculate-eo/",
  calculateCorrelationSscDischarge:
    "/analyse/correlations-discharge/calculate-ssc/",
  calculateSediment: "/analyse/sediment/calculate/", // old
  calculateCorrelationFlux: "/analyse/sediment/calculate_flux/",
  sediment: "/analyse/sediment/",
  sedimentStations: regionId => {
    return `/vector/region/${regionId}/layer-geometries/`;
  },
  calculateSedimentRate: "/analyse/sediment-rate/calculate/",
  sedimentRate: "/analyse/sediment-rate/",
  powerProductions: regionId => {
    return `/hypos/region/${regionId}/power-production`;
  },
  geojsonLayer: regionId => {
    return `/vector/${Url.client()}/region/${regionId}/geojson-layer/`;
  },
  geojsonData: (regionId, timestep) => {
    return `/vector/${Url.client()}/region/${regionId}/geojson-layer/${timestep}/`;
  }
};

export default Url;
