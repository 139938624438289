const state = {
  layer: null,
  timestep: null,
  lat: null,
  lng: null,
  zoom: null
};

const mutations = {
  SET_LAYER(state, value) {
    state.layer = value;
  },
  SET_TIMESTEP(state, value) {
    state.timestep = value;
  },
  SET_LAT(state, value) {
    state.lat = value;
  },
  SET_LNG(state, value) {
    state.lng = value;
  },
  SET_ZOOM(state, value) {
    state.zoom = value;
  },
  REMOVE_TIMESTEP(state) {
    state.timestep = null;
  }
};

const actions = {
  setLayer({ commit }, value) {
    commit("SET_LAYER", value);
  },
  setTimestep({ commit }, value) {
    commit("SET_TIMESTEP", value);
  },
  setLat({ commit }, value) {
    commit("SET_LAT", value);
  },
  setLng({ commit }, value) {
    commit("SET_LNG", value);
  },
  setZoom({ commit }, value) {
    commit("SET_ZOOM", value);
  },
  removeTimestep({ commit }) {
    commit("REMOVE_TIMESTEP");
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
