const state = {
  selectedLayerTopMap: null,
  selectedLayerBottomMap: null,
  timeLayer: null
};
const getters = {
  getSelectedLayerTopMap(state) {
    return state.selectedLayerTopMap;
  },
  getSelectedLayerBottomMap(state) {
    return state.selectedLayerBottomMap;
  },
  getTimeLayer(state) {
    return state.timeLayer;
  }
};
const actions = {
  setSelectedLayerTopMap({ commit }, selectedLayer) {
    commit("SET_SELECTED_LAYER_TOP_MAP", selectedLayer);
  },
  setSelectedLayerBottomMap({ commit }, selectedLayer) {
    commit("SET_SELECTED_LAYER_BOTTOM_MAP", selectedLayer);
  },
  setTimeLayer({ commit }, timeLayer) {
    commit("SET_TIME_LAYER", timeLayer);
  }
};
const mutations = {
  SET_SELECTED_LAYER_TOP_MAP(state, selectedLayer) {
    state.selectedLayerTopMap = selectedLayer;
  },
  SET_SELECTED_LAYER_BOTTOM_MAP(state, selectedLayer) {
    state.selectedLayerBottomMap = selectedLayer;
  },
  SET_TIME_LAYER(state, timeLayer) {
    state.timeLayer = timeLayer;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
