<template>
  <div class="view-height" style="overflow-y: auto; overflow-x: hidden">
    <div class="overview-map" :id="regionMapId"></div>
  </div>
</template>

<script>
import bbox from "@turf/bbox";
import mapbox from "mapbox-gl";
import MapboxConfig from "@/core/plugins/mapbox";
import centerOfMass from "@turf/center-of-mass";
export default {
  name: "RegionsMap",
  data: () => ({
    mapLoading: true,
    regionMapId: "regionsOverview"
  }),
  props: {
    regionFeatures: Object
  },
  computed: {
    regionsBbox() {
      return bbox(this.regionFeatures);
    },
    featureCollection() {
      return JSON.parse(JSON.stringify(this.regionFeatures));
    }
  },
  methods: {
    getCenterOfBbox(feature) {
      const center = centerOfMass(feature.geometry);
      return center;
    },
    initMap() {
      mapbox.accessToken = MapboxConfig.accessToken;
      const initMap = new mapbox.Map({
        container: this.regionMapId,
        style: MapboxConfig.style,
        bounds: this.regionsBbox
      });

      const mapPromise = new Promise(resolve => {
        initMap.on("load", () => {
          resolve(initMap);
          this.map = initMap;
          this.addRegionsToMap();
        });
      });
      return { initMap, mapPromise };
    },
    addRegionsToMap() {
      this.featureCollection.features.forEach(feature => {
        const center = this.getCenterOfBbox(feature);
        center.properties = feature.properties;
        this.featureCollection.features.push(center);
      });
      this.map.addSource("regionsSource", {
        type: "geojson",
        data: this.featureCollection
      });

      this.map.addLayer({
        id: "regionsArea",
        type: "fill",
        source: "regionsSource",
        layout: {},
        paint: {
          "fill-color": "rgba(0,0,0,0)"
        }
      });

      this.map.addLayer({
        id: "regionsOutline",
        type: "line",
        source: "regionsSource",
        minzoom: 6,
        paint: {
          "line-color": "#1D4180",
          "line-width": 3
        },
        filter: ["==", "$type", "Polygon"]
      });

      this.map.addLayer({
        id: "regionsDots",
        type: "circle",
        source: "regionsSource",
        maxzoom: 6,
        paint: {
          "circle-color": "#1D4180"
        },
        filter: ["==", "$type", "Point"]
      });

      //
      this.map.addLayer({
        id: "regionsLabel",
        type: "symbol",
        source: "regionsSource",
        layout: {
          "icon-image": "custom-marker",
          "text-field": ["get", "name"],
          "icon-allow-overlap": true,
          "text-anchor": "bottom-right",
          "text-radial-offset": 0.4,
          "text-allow-overlap": false
        },
        filter: ["==", "$type", "Point"]
      });

      this.map.on("mouseenter", "regionsArea", () => {
        this.map.getCanvas().style.cursor = "pointer";
      });

      this.map.on("mouseleave", "regionsArea", () => {
        this.map.getCanvas().style.cursor = "";
      });

      this.map.on("click", "regionsArea", e => {
        const regionId = e.features[0].id;
        this.$router.push({
          name: "SelectedRegion",
          params: { regionId: regionId }
        });
      });
    }
  },
  mounted() {
    const map = this.initMap();
    const mapPromise = map.mapPromise;
    mapPromise.then(mapObject => {
      this.map = mapObject;
      this.$emit("loadingFinish");
    });
  }
};
</script>

<style scoped>
.overview-map {
  height: 60vh;
  width: 100%;
}
</style>
