<template>
  <v-menu
    left
    open-on-hover
    :close-on-content-click="false"
    offset-y
    z-index="999"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        class="ma-1"
        v-bind="attrs"
        v-on="on"
        v-if="$vuetify.breakpoint.lgAndUp"
      >
        {{ $t("bar.welcome") }} {{ $keycloak.tokenParsed.given_name }}
        <v-icon class="ml-1" size="xx-small">mdi-triangle-down</v-icon>
      </v-btn>
      <v-btn icon class="ma-1" v-bind="attrs" v-on="on" v-else>
        <v-icon large>mdi-account-check-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list class="pb-0">
        <v-list-item>
          <v-list-item-avatar>
            <v-icon large>mdi-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $keycloak.fullName }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ $keycloak.idTokenParsed.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          style="background-color: #ffe0b2"
          class="ml-2 mr-2"
        ></v-divider>
        <v-list class="py-0" v-if="$appConfig.keycloakClient !== 'coasts'">
          <v-list-item @click="navigate('AccountDetails')">
            {{ $t("accountDetails.accountDetails") }}
          </v-list-item>
        </v-list>
      </v-list>
      <v-divider
        style="background-color: #ffe0b2"
        class="ml-2 mr-2"
      ></v-divider>

      <v-card-actions>
        <div
          cols="auto"
          v-for="lang in availableLanguagesDict"
          :key="lang.value"
        >
          <v-btn
            class="pr-0 pl-0"
            :color="language == lang.value ? 'primary' : ''"
            @click="language = lang.value"
            icon
            text
            :label="lang.text"
            >{{ lang.value }}
          </v-btn>
          |
        </div>
        <v-spacer />
        <v-btn text color="primary" @click="$keycloak.logoutFn"
          >{{ $t("bar.logout") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "ProfileMenu",
  data: () => ({
    availableLanguagesDict: [],
    reg: []
  }),
  computed: {
    ...mapState("management", ["ui_language", "languages_dict"]),
    ...mapGetters("management", ["activeRegion"]),

    language: {
      get() {
        return this.ui_language;
      },
      set(value) {
        this.updateLanguageSelection(value);
      }
    }
  },
  methods: {
    ...mapActions("management", [
      "updateUserProfileLanguage",
      "fetchUserProfile",
      "getOrFetchRegions"
    ]),
    updateLanguageSelection(selectedLanguage) {
      this.$i18n.locale = selectedLanguage;
      this.updateUserProfileLanguage(selectedLanguage);
    },
    navigate(routeName) {
      this.$router.push({ name: routeName }).catch(err => {
        if (err.name !== "NavigationDuplicated") {
          throw err;
        }
      });
    },
    navigateWithRegionId(routeName) {
      let regionId;

      if (this.activeRegion) {
        regionId = this.activeRegion.id;
      } else {
        regionId = this.reg[0].id;
      }

      if (regionId) {
        this.$router
          .push({ name: routeName, params: { regionId: regionId } })
          .catch(err => {
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
          });
      } else {
        this.navigate(routeName);
      }
    }
  },
  async beforeMount() {
    const results = await this.getOrFetchRegions();
    this.reg = results.features;
  },
  created() {
    this.fetchUserProfile().then(data => {
      this.$i18n.locale = data.ui_language;
    });
    const availableLocales = this.$i18n.availableLocales;
    this.languages_dict.map(lang => {
      if (availableLocales.includes(lang.value)) {
        this.availableLanguagesDict.push(lang);
      }
    });
  }
};
</script>

<style scoped></style>
