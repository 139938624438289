<template>
  <v-dialog
    v-model="dialog"
    :width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '95vw' : '75vw'"
    style="
    z-index: 1000;
"
  >
    <v-card class="fill-height water" height="90vh">
      <v-toolbar dense flat>
        <v-toolbar-title class="text-h6" style="color: #173466"
          >Support Center</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <!-- "Hey" Row -->
        <v-layout row pa-1 fill-height>
          <v-flex
            :class="{
              xs12: true,
              sm3: !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm,
              sm1: $vuetify.breakpoint.xs || $vuetify.breakpoint.sm,
              'pa-2': true
            }"
          >
          </v-flex>

          <v-flex
            :class="{
              xs12: true,
              sm9: !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm,
              sm11: $vuetify.breakpoint.xs || $vuetify.breakpoint.sm,
              'pb-2': true
            }"
          >
            <span class="pl-5"
              >Hey, {{ this.userInfo[1]
              }}<v-icon color="#039BE5" class="pl-2"
                >mdi-hand-wave</v-icon
              ></span
            >
          </v-flex>
        </v-layout>
        <!-- Content -->
        <v-layout row pa-1 fill-height>
          <!-- Left Side Bar -->
          <v-flex
            :class="{
              xs12: true,
              sm3: !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm,
              sm1: $vuetify.breakpoint.xs || $vuetify.breakpoint.sm,
              'pa-2': true
            }"
            style="border-right: 1px solid #000;"
          >
            <SupportCenterSidebar @change-view="handleChangeView" />
          </v-flex>
          <!-- Right Side Content -->
          <v-flex
            :class="{
              xs12: true,
              sm9: !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm,
              sm11: $vuetify.breakpoint.xs || $vuetify.breakpoint.sm,
              'pa-2': true,
              'fixed-height': true,
              'scrollable-container': true
            }"
            :style="
              currentView === 'SubscriptionTable'
                ? { backgroundColor: '#f0f2f4' }
                : { backgroundColor: 'white' }
            "
          >
            <component :is="currentView" v-bind="additionalProps"></component>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import SupportCenterSidebar from "@/core/components/bar/supportCenter/SupportCenterSidebar";
import FAQComponent from "@/core/components/bar/supportCenter/FAQComponent";
import UserManualComponent from "@/core/components/bar/supportCenter/UserManualComponent";
import GettingStarted from "@/core/components/bar/supportCenter/GettingStarted";
import SubscriptionTable from "@/core/components/welcomeView/SubscriptionTable";
import ContactForm from "@/core/components/bar/supportCenter/ContactForm";

export default {
  components: {
    SupportCenterSidebar,
    FAQComponent,
    UserManualComponent,
    GettingStarted,
    SubscriptionTable,
    ContactForm
  },
  props: { value: Boolean, userInfo: Array },
  data() {
    return {
      dialog: false,
      currentView: "GettingStarted",
      additionalProps: {}
    };
  },
  methods: {
    handleChangeView(viewName) {
      this.currentView = viewName;
      if (viewName === "ContactForm") {
        this.additionalProps = { userInfo: this.userInfo };
      } else if (viewName === "SubscriptionTable") {
        this.additionalProps = {
          width: 350,
          padding: "0 0"
        };
      } else {
        this.additionalProps = {};
      }
    }
  },

  watch: {
    value(newVal) {
      this.dialog = newVal;
    },
    dialog(newVal) {
      if (newVal === false) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>
<style scoped>
.fixed-height {
  height: 80vh;
  overflow-y: auto;
}
.scrollable-container {
  overflow-y: auto;
}

.scrollable-container::-webkit-scrollbar {
  width: 10px;
}

.scrollable-container::-webkit-scrollbar-track {
  background: #e9eff5;
  border-radius: 8px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #e9eff5;
  border-radius: 8px;
}

.scrollable-container {
  scrollbar-width: thin;
  scrollbar-color: #e2eef5 #f1f1f1;
}
</style>
