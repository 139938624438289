<template>
  <v-container fluid class="fill-height">
    <iframe style="height: 100%; width: 100%" :src="userManualUrl"></iframe>
  </v-container>
</template>

<script>
export default {
  computed: {
    userManualUrl() {
      if (this.$i18n.locale === "de") {
        return this.$appConfig.manualUrlDe;
      } else {
        return this.$appConfig.manualUrl;
      }
    }
  }
};
</script>
