<template>
  <section v-if="!xs" :style="{ padding: computedPadding }">
    <v-container class="pl-10">
      <v-row>
        <v-col class="d-flex justify-end">
          <v-card :style="{ width: computedWidth }">
            <v-card-title class="plan-title" style="background:#f6f9f9">
              <span class="heading-3">
                {{ $t("order.servicesChoiceStep.standardPlan") }}</span
              >
              <v-spacer />
            </v-card-title>
            <v-card-text class="mb-0">
              <v-row class="plan-section" v-for="row in plans" :key="row.title">
                <v-col class="px-0 mx-0 col-section">
                  <v-icon :color="row.standard ? '#173466' : '#8996AB'">
                    {{ row.icon }}</v-icon
                  >
                  <span
                    :style="{ color: row.standard ? '#173466' : '#8996AB' }"
                    >{{ $t(`${row.title}`) }}</span
                  >
                  <v-spacer />
                  <v-icon color="green" v-if="row.standard"
                    >mdi-check-circle-outline</v-icon
                  >
                  <v-icon v-else>mdi-minus</v-icon>
                </v-col>
              </v-row></v-card-text
            >
          </v-card>
        </v-col>
        <v-col class="d-flex justify-start">
          <v-card :style="{ width: computedWidth }">
            <v-card-title
              class="plan-title"
              style="background:#f6f9f9
              "
            >
              <span class="heading-3">
                {{ $t("order.servicesChoiceStep.premiumPlan") }}</span
              >
              <v-spacer />
            </v-card-title>
            <v-card-text class="mb-0">
              <v-row class="plan-section" v-for="row in plans" :key="row.title">
                <v-col class="px-0 mx-0 col-section">
                  <v-icon :color="row.premium ? '#173466' : '#8996AB'">
                    {{ row.icon }}</v-icon
                  >
                  <span
                    :style="{ color: row.premium ? '#173466' : '#8996AB' }"
                    >{{ $t(`${row.title}`) }}</span
                  >
                  <v-spacer />
                  <v-icon color="green" v-if="row.premium"
                    >mdi-check-circle-outline</v-icon
                  >
                  <v-icon v-else>mdi-minus</v-icon>
                </v-col>
              </v-row></v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "SubscriptionTable",
  props: {
    client: {},
    contentInfos: {},
    width: Number,
    padding: String
  },
  data() {
    return {
      headers: [{ text: "Functions", value: "functions" }],
      plans: [
        {
          icon: "mdi-satellite-variant",
          title: "order.servicesChoiceStep.displayEODate",
          standard: true,
          premium: true
        },
        {
          icon: "mdi-file-upload-outline",
          title: "order.servicesChoiceStep.uploadExternalData",
          standard: true,
          premium: true
        },
        {
          icon: "mdi-vector-polygon",
          title: "order.servicesChoiceStep.defineVS",
          standard: true,
          premium: true
        },
        {
          icon: "mdi-chart-timeline",
          title: "order.servicesChoiceStep.extractTimeSeries",
          standard: true,
          premium: true
        },
        {
          icon: "mdi-history",
          title: "order.servicesChoiceStep.histData",
          standard: true,
          premium: true
        },
        {
          icon: "mdi-chart-timeline-variant-shimmer",
          title: "order.servicesChoiceStep.monitoring",
          standard: true,
          premium: true
        },
        {
          icon: "mdi-poll",
          title: "order.servicesChoiceStep.statistics",
          standard: false,
          premium: true
        },
        {
          icon: "mdi-compare",
          title: "order.servicesChoiceStep.compareSites",
          standard: false,
          premium: true
        },
        {
          icon: "mdi-view-dashboard",
          title: "order.servicesChoiceStep.alertDashboard",
          standard: false,
          premium: true
        },
        {
          icon: "mdi-chart-line",
          title: "order.servicesChoiceStep.monitoringDashboard",
          standard: false,
          premium: true
        },
        {
          icon: "mdi-bell-outline",
          title: "order.servicesChoiceStep.defineThresholds",
          standard: false,
          premium: true
        },
        {
          icon: "mdi-email-outline",
          title: "order.servicesChoiceStep.emailNotification",
          standard: false,
          premium: true
        }
      ],
      xs: {}
    };
  },
  computed: {
    computedWidth() {
      return this.width || "500px";
    },
    computedPadding() {
      return this.padding || "82px 0";
    }
  },
  methods: {
    itemIndexIsEven(item) {
      return this.data.indexOf(item) % 2 === 0;
    },
    getIndex(item) {
      return this.data.indexOf(item);
    }
  },
  created() {
    this.xs = this.$vuetify.breakpoint.xs;
  }
};
</script>
<style scoped>
section {
  background-color: #f0f2f4;
}

.grey--text {
  background-color: #f5f5f5;
}

.plan-title {
  height: 76px;

  /* custom/offwhite */
  /* custom/gray01 */
  border-bottom: 1px solid #e9eff5;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.plan-section {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;

  /* custom/gray01 */
  border-bottom: 1px solid #e9eff5;

  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}
.col-section {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 0px;

  /* Inside auto layout */
}
</style>
