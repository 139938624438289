import Vue from "vue";
import Vuex from "vuex";
import app from "@/core/store/app.store";
import management from "@/core/store/management.store";
import raster from "@/core/store/raster.store";
import vector from "@/core/store/vector.store";
import draw from "@/core/store/draw.store";
import plot from "@/core/store/plot.store";
import analyse from "@/core/store/analyse.store";
import userdata from "@/core/store/userdata.store";
import dashboard from "@/core/store/dashboard.store";
import coastsPlot from "@/core/store/coastsPlot.store";
import baseline from "@/core/store/baseline.store";
import download from "@/core/store/download.store";
import valuePopup from "@/core/store/valuePopup.store";
import inSitu from "@/core/store/inSitu.store";
import queryParams from "@/core/store/queryParams.store";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    app,
    management,
    raster,
    vector,
    draw,
    plot,
    analyse,
    userdata,
    dashboard,
    baseline,
    valuePopup,
    coastsPlot,
    download,
    inSitu,
    queryParams
  }
});
