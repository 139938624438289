<template>
  <section>
    <v-container>
      <!--      <v-row class="text-center mb-12">-->
      <!--        <v-col class="text-h4">{{-->
      <!--          $t("header")-->
      <!--        }}</v-col>-->
      <!--      </v-row>-->
      <v-row>
        <v-col :class="classObject">
          <before-after-image
            :before-image="imageBackground"
            :after-image="imageForeground"
          >
          </before-after-image>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import BeforeAfterImage from "@/core/components/welcomeView/BeforeAfterImage.vue";

export default {
  name: "ImageComparisonSection",
  components: { BeforeAfterImage },
  props: {
    client: {},
    contentInfos: {}
  },
  data() {
    return {
      lgAndUp: {}
    };
  },
  computed: {
    classObject() {
      return {
        "px-16": this.lgAndUp,
        "mx-16": this.lgAndUp
      };
    },
    imageForeground() {
      return this.contentInfos.imageCompareSection.imageForeground;
    },
    imageBackground() {
      return this.contentInfos.imageCompareSection.imageBackground;
    }
  },
  created() {
    this.lgAndUp = this.$vuetify.breakpoint.lgAndUp;
  }
};
</script>

<style scoped>
section {
  padding: 82px 0;
  background-color: #f0f2f4;
}
</style>
