import Vue from "vue";
import App from "@/App.vue";
import router from "@/core/router";
import store from "@/core/store";
import vuetify from "@/core/plugins/vuetify";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import i18n from "@/localization/i18n";
import tokenInterceptor from "@/core/services/interceptors";
import axios from "axios";
import AppUtils from "@/app/utils";
import { sync } from "vuex-router-sync";
import "@/core/assets/sass/style.scss";

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

const appConfig = AppUtils.getConfig();
Vue.prototype.$appConfig = appConfig;

Vue.prototype.$rastless = axios.create({
  baseURL: process.env.VUE_APP_RASTLESS_URL
});

Vue.prototype.$credit = axios.create({
  baseURL: process.env.VUE_APP_CREDIT_URL
});

Vue.use(VueKeyCloak, {
  init: {
    onLoad: "check-sso"
  },
  config: {
    url: process.env.VUE_APP_KC_URL,
    clientId: appConfig.keycloakClient,
    realm: process.env.VUE_APP_REALM,
    logoutRedirectUri: window.location.origin
  },
  onReady: () => {
    tokenInterceptor();
    sync(store, router);
    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App)
    }).$mount("#app");
  },
  onInitError: error => {
    console.log(error);
  }
});
