import router from "@/core/router";
import { getIndexById } from "@/core/utils/utils";

const state = {
  selectedFeatureId: null,
  virtualStations: [],
  newStation: { name: "", permanent: false }
};

const getters = {
  selectedFeature: state => {
    if (state.selectedFeatureId) {
      return [
        state.virtualStations.find(
          entry => entry.id === state.selectedFeatureId
        )
      ];
    }
    return [];
  },
  stationById: state => id => {
    const entryIndex = getIndexById({ array: state.virtualStations, id: id });
    return state.virtualStations[entryIndex];
  },
  virtualStationsByRegion: state => {
    const activeRegion = router.currentRoute.params.regionId;
    return state.virtualStations.filter(entry => entry.region === activeRegion);
  }
};

const actions = {
  setSelectedFeatureId({ commit }, id) {
    commit("SET_SELECTED_FEATURE_ID", id);
  },
  addVirtualStation({ commit, rootGetters }, feature) {
    const activeRegionId = rootGetters["management/activeRegion"].id;
    let tempStationName = "";
    if (!feature.name) {
      tempStationName = new Date().toISOString().slice(0, 22);
    } else {
      tempStationName = feature.name;
    }
    const featureEntry = {
      name: tempStationName,
      id: feature.id,
      geometry: feature.geometry,
      permanent: false,
      region: activeRegionId
    };
    commit("SET_NEW_STATION", featureEntry);
    commit("ADD_VIRTUAL_STATION", featureEntry);
  },
  addPermanentVirtualStations({ commit }, stationArray) {
    commit("ADD_PERMANENT_VIRTUAL_STATIONS", stationArray);
  },
  deleteVirtualStation({ commit }, id) {
    commit("DELETE_VIRTUAL_STATION", id);
  },
  updateName({ commit }, { item, name }) {
    commit("UPDATE_STATION_NAME", { item, name });
  },
  setVSPermanent({ commit }, id) {
    commit("SET_VS_PERMANENT", id);
  }
};

const mutations = {
  SET_SELECTED_FEATURE_ID(state, id) {
    state.selectedFeatureId = id;
  },
  ADD_VIRTUAL_STATION(state, entry) {
    state.virtualStations.push(entry);
  },
  ADD_PERMANENT_VIRTUAL_STATIONS(state, stationArray) {
    state.virtualStations = stationArray;
  },
  SET_NEW_STATION(state, entry) {
    state.newStation = entry;
  },
  DELETE_VIRTUAL_STATION(state, id) {
    state.virtualStations = state.virtualStations.filter(
      entry => entry.id !== id
    );
  },
  UPDATE_STATION_NAME(state, { item, name }) {
    const entryIndex = getIndexById({
      array: state.virtualStations,
      id: item.id
    });
    state.virtualStations[entryIndex].name = name;
  },
  SET_VS_PERMANENT(state, id) {
    const entryIndex = getIndexById({ array: state.virtualStations, id: id });
    state.virtualStations[entryIndex].permanent = true;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
