<template>
  <v-tabs
    fixed-tabs
    v-if="showToolbar && features.addFeatures"
    :grow="tab_grow"
    v-model="tab"
  >
    <v-tab
      class="hidden-xs-only"
      v-for="feature in features.coreFeatures"
      :disabled="!feature.available"
      :key="feature.label"
      :to="feature.route"
    >
      <div>
        <v-icon class="justify-center">{{ feature.icon }}</v-icon>
        <br />
        <span style="color: #536380" class="icon-text">{{
          $t(`${feature.label}`)
        }}</span>
      </div>
      <!--      <v-icon :title="feature.label">{{ feature.icon }}</v-icon>-->
    </v-tab>
    <v-tab class="hidden-xs-only">
      <features-menu
        :features="features.addFeatures"
        :region="region"
        @changeTab="tab = 3"
      ></features-menu>
    </v-tab>
    <v-tab class="d-block d-sm-none">
      <features-menu :features="getAllFeatures()"></features-menu>
    </v-tab>
  </v-tabs>
</template>

<script>
import FeaturesMenu from "@/core/components/regionsOverview/FeaturesMenu.vue";
import serviceSelectionMixin from "@/core/mixins/serviceSelection.mixin";

export default {
  name: "HeaderButtons",
  mixins: [serviceSelectionMixin],
  components: { FeaturesMenu },
  props: {
    tab_grow: {
      type: Boolean,
      default: () => false
    },
    region: Object
  },
  data: () => ({
    tab: null,
    features: []
  }),
  computed: {
    showToolbar() {
      return this.$route.meta?.showToolbar;
    },
    regionId() {
      return this.$route.params.regionId;
    }
  },
  methods: {
    getAllFeatures() {
      let all = this.features.coreFeatures;
      if (all) {
        all = all.concat(this.features.addFeatures);
        return all;
      }
      return [];
    }
  },
  watch: {
    async "$route.params.regionId"() {
      this.features = await this.getFeatures(this.region.id, this.region);
    },
    tab() {
      const featurePosition = this.features.addFeatures.filter(
        feature => feature.route.name == this.$route.name
      );
      if (featurePosition.length) {
        this.tab = 3;
      }
      if (this.$route.name == "monitoring") {
        this.tab = 1;
      } else if (this.$route.name == "baseline") {
        this.tab = 2;
      }
    }
  },
  async created() {
    this.features = await this.getFeatures(this.region.id, this.region);
  }
};
</script>

<style scoped>
#eo-functionalities {
  margin-left: 1rem;
}
.icon-text {
  text-align: center;
  /* v-text/graph style1 */
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 8px;
  line-height: 0px; /* 0% */
  letter-spacing: 0.25px;
  text-transform: uppercase;
}
</style>
