<template>
  <v-menu
    z-index="999"
    right
    :close-on-content-click="true"
    offset-y
    v-if="features.length"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="place == 'header'"
        elevation="0"
        plain
        width="60px"
        class="px-0"
        v-bind="attrs"
        v-on="on"
        @click="$emit('changeTab')"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
      <v-btn
        v-else
        elevation="0"
        plain
        fab
        small
        v-bind="attrs"
        v-on="on"
        @click="$emit('changeTab')"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-card class="overflow-hidden" style="width: 300px; height: auto">
      <!--      <v-card-title> Additional Features </v-card-title>-->
      <v-card-text>
        <div>
          <v-row class="py-5 menu-row">
            <v-col
              v-for="(line, index) in featuresItems"
              :key="line.icon"
              sm="12"
              md="6"
              align="center"
            >
              <v-row>
                <v-col
                  v-if="line.icon != 'mdi-delete-forever'"
                  style="height: 94px"
                >
                  <div
                    @click="navigate(line.route)"
                    @mouseover="changeCursor('pointer')"
                    @mouseout="changeCursor('auto')"
                    class="div-hover"
                  >
                    <span
                      v-if="line.googleIcon"
                      class="icon-symbol material-icons-outlined justify-center"
                    >
                      {{ line.googleIcon }}
                    </span>
                    <span v-else>
                      <v-icon large class="justify-center py-1">
                        {{ line.icon }}
                      </v-icon>
                    </span>
                    <br />
                    <span class="icon-text">{{ $t(`${line.label}`) }}</span>
                  </div>
                </v-col>
                <v-col v-else>
                  <div
                    @mouseover="changeCursor('pointer')"
                    @mouseout="changeCursor('auto')"
                  >
                    <confirm-region-delete
                      v-if="line.available"
                      :region="region"
                      :place="'featureMenu'"
                    ></confirm-region-delete>
                  </div>
                </v-col>
                <v-divider v-if="index % 2 === 0" vertical></v-divider>
              </v-row>
              <v-row class="pt-3">
                <v-divider
                  v-if="
                    (index % 2 === 0 && index < featuresItems.length - 2) ||
                      (index % 2 !== 0 && index < featuresItems.length - 1)
                  "
                ></v-divider>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import ConfirmRegionDelete from "@/core/components/accountDetails/ConfirmRegionDelete.vue";

export default {
  name: "FeaturesMenu",
  components: { ConfirmRegionDelete },
  props: {
    region: { type: Object, default: () => {} },
    features: Array,
    place: { type: String, default: () => "header" }
  },
  computed: {
    featuresItems() {
      let features = [...this.features];
      features = features.filter(feature => feature.available);
      return features;
    },
    showToolbar() {
      return this.$route.meta?.showToolbar;
    },
    regionId() {
      if (this.$route.params.regionId) {
        return this.$route.params.regionId;
      } else {
        return this.region.id;
      }
    }
  },
  methods: {
    navigate(route) {
      this.$router.push(route);
      this.changeCursor("auto");
    },
    changeCursor(style) {
      document.querySelector("div").style.cursor = style;
    }
  }
};
</script>

<style scoped>
v-icon {
  font-size: 24px;
  margin: 8px;
}

v-divider {
  height: 40px;
}

.icon-text {
  /* v-text/body-small */
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.icon-symbol {
  font-size: 38px;
}

.menu-row {
  padding-top: 3px !important;
  padding-bottom: 0 !important;
}

.div-hover:hover {
  background-color: #f5f5f5;
  border-radius: 5px;
}
</style>
