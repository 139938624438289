<template>
  <section>
    <v-container>
      <v-carousel
        :interval="12000"
        v-model="tab"
        cycle
        :light="true"
        hide-delimiter-background
        :show-arrows="false"
      >
        <v-carousel-item v-for="item in quoteItems" :key="item.title">
          <v-sheet color="white" height="100%" tile>
            <v-row class="mx-16 fill-height align-center">
              <v-col cols="4" v-if="!xs">
                <v-img height="200px" contain :src="item.icon"></v-img>
              </v-col>
              <v-col
                class="center d-flex flex-column"
                style="overflow-wrap: break-word"
              >
                <div
                  style="color: black; font-size: 18px"
                  class="font-weight-light font-italic"
                >
                  {{ $t(item.text) }}
                </div>
                <div
                  class="text-h5 font-weight-light mt-4"
                  style="color: black; font-size: 18px"
                >
                  {{ $t(item.person) }}
                </div>
                <div
                  v-if="item.company"
                  class="font-weight-light"
                  style="color: grey; font-size: 18px"
                >
                  {{ $t(item.company) }}
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "UserQuotes",
  props: {
    client: {},
    contentInfos: {}
  },
  data() {
    return {
      xs: {},
      tab: 0
    };
  },
  computed: {
    quoteItems() {
      return this.contentInfos.userQuotes.quoteItems;
    }
  },
  created() {
    this.xs = this.$vuetify.breakpoint.xs;
  }
};
</script>

<style scoped>
section {
  padding: 42px 0;
  background-color: white;
}

.v-carousel__controls__item.v-btn.v-btn--icon {
  background-color: #ebece9; /* Background color of non-active ones */
  height: 2px; /* Height you want */
  width: 40px; /* Width you want */
  border-radius: 0; /* Remove default border radius */
}

.v-carousel__controls__item.v-btn.v-btn--icon.v-btn--active {
  background-color: #1f1f1f; /* Colour for active one */
}

.v-carousel__controls__item.v-btn.v-btn--icon:hover {
  background-color: black; /* You might also want to customise the hover effect */
}

.v-btn__content .v-icon {
  display: none; /* Removes the default icon */
}
</style>
