<template>
  <div v-if="$appConfig.keycloakClient != 'coasts'">
    <span
      v-if="showToolbar"
      class=" region-name hidden-sm-and-down"
      :style="{ color: 'primary' }"
      @mouseover="changeCursor('default')"
      @mouseout="changeCursor('auto')"
    >
      {{ regionName }}
    </span>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HomeBtn",
  data: () => ({
    isHovered: false
  }),
  props: {
    tab_grow: {
      type: Boolean,
      default: () => false
    },
    region: Object
  },
  computed: {
    ...mapState("management", ["regions"]),
    showToolbar() {
      return this.$route.meta?.showToolbar;
    },
    regionName() {
      return this.region.properties.name;
    }
  },
  methods: {
    changeCursor(style) {
      document.querySelector("div").style.cursor = style;
    }
  }
};
</script>

<style>
#eo-functionalities {
  margin-left: 1rem;
}
.region-name {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  color: #173466;
}
</style>
