import Vue from "vue";
import axios from "axios";
import store from "@/core/store";

export default async function tokenInterceptor() {
  axios.interceptors.request.use(
    async config => {
      if (Vue.prototype.$keycloak.authenticated) {
        await Vue.prototype.$keycloak.keycloak.updateToken(70);
        config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    response => {
      return Promise.resolve(response);
    },
    error => {
      console.log(error);
      if (error.response && error.response.status) {
        const message = error.response.config.url || "";
        let detail = "";
        switch (error.response.status) {
          case 401:
            store.dispatch("app/showSnackbar", {
              show: true,
              message: `${error} - ${message}`,
              color: "error"
            });
            break;
          case 400:
            store.dispatch("app/showSnackbar", {
              show: true,
              message: `${error} - ${message}`,
              color: "error"
            });
            break;
          case 500:
            store.dispatch("app/showSnackbar", {
              show: true,
              message: `${error} - ${message}`,
              color: "error"
            });
            break;
          case 422:
            if (
              error.response.data != undefined &&
              error.response.data.detail != undefined
            ) {
              detail = error.response.data.detail;
            }
            store.dispatch("app/showSnackbar", {
              show: true,
              message: `Unprocessable. ${error} - ${message} ${detail}`,
              color: "warning"
            });
            break;
          case 404:
            store.dispatch("app/showSnackbar", {
              show: true,
              message: `${error} - Not found or not the owner of the data.`,
              color: "error"
            });
            break;
        }
      }
      return Promise.reject(error);
    }
  );
}
