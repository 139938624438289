<template>
  <section>
    <v-container>
      <v-row class="text-center mb-12">
        <v-col class="text-h4">{{ $t(info.heading) }}</v-col>
      </v-row>
      <v-row class="text-center justify-center">
        <v-col class="text-h6 font-weight-light"
          ><span v-html="$t(info.text)"></span
        ></v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  name: "TextSection",
  props: {
    client: {},
    contentInfos: {}
  },
  computed: {
    info() {
      return this.contentInfos.textSection;
    }
  }
};
</script>

<style scoped>
section {
  padding: 82px 0;
  background-color: #f0f2f4;
}
</style>
