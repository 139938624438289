<template>
  <section>
    <v-container>
      <v-row>
        <v-col class="text-center">
          <v-btn
            color="#40add5"
            variant="text"
            href="https://www.linkedin.com/company/eomap-gmbh-&-co--kg"
            target="_blank"
            icon
          >
            <v-icon>mdi-linkedin</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="text-white text-body-2">
            <a
              href="https://www.eomap.com"
              target="_blank"
              style="color: #40add5"
              class="text-body-2 pr-1"
            >
              EOMAP GmbH & Co. KG
            </a>
            <span style="color: white">
              | Castle Seefeld | Schlosshof 4a | D-82229 Seefeld</span
            >
          </p>
          <p>
            <a
              v-if="client == 'xylem'"
              href="mailto:eoapp@xylem.com; xylem@eomap.de"
              target="_blank"
              style="color: #40add5"
              class="text-body-2"
            >
              eoapp@xylem.com
            </a>
            <a
              v-else
              href="mailto:info@eomap.de"
              target="_blank"
              style="color: #40add5"
              class="text-body-2"
            >
              info@eomap.de
            </a>
            <span class="text-white px-1" style="color: white">|</span>
            <a
              href="https://www.eomap.com/legal-information/"
              target="_blank"
              style="color: #40add5"
              class="text-body-2"
            >
              Legal Notice & Privacy Policy
            </a>
          </p>
        </v-col>
        <v-col class="text-right text-white text-body-2" style="color: white">
          © EOMAP GmbH & Co. KG, 2023
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "FooterSection",
  computed: {
    client() {
      return this.$appConfig.keycloakClient;
    }
  }
};
</script>

<style scoped>
section {
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: #757575;
}
</style>
