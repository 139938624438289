import axios from "axios";
import Vue from "vue";

const state = {
  inSituStations: [],
  inSituStationsLoaded: false,
  selectedInSituStation: {},
  associatePopUp: false,
  associations: [],
  dataForResultView: [],
  dataForResultViewHeaders: {
    associatedHeaders: [],
    notAssociatedHeaders: []
  },
  compareColorsInSituData: ["#2582AC", "#173466"],
  singleColorInSituData: "#173466",
  uploadInSituDataDialogVisible: false
};

const getters = {
  getSelectedInSituStation(state) {
    return state.selectedInSituStation;
  }
};

const actions = {
  async fetchInSituData({ commit }, { vector_layer_id }) {
    commit("IN_SITU_STATIONS_LOADED", false);
    try {
      const client = Vue.prototype.$appConfig.keycloakClient;

      const response = await axios.get(
        `/vector/${client}/layers/${vector_layer_id}/geometry`
      );
      response.data.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      commit("SET_SELECTED_IN_SITU_STATION", response.data[0]);

      commit("SET_ALL_IN_SITU_STATIONS", response.data);
    } catch (err) {
      console.log(err);
    }
    commit("IN_SITU_STATIONS_LOADED", true);
  },
  updateSelectedInSituStation({ commit }, payload) {
    commit("SET_SELECTED_IN_SITU_STATION", payload);
  },
  updateAssociatePopUpStatus({ commit }, status) {
    commit("SET_ASSOCIATE_POP_UP_STATUS", status);
  },
  updateDataForResultView({ commit }, data) {
    commit("UPDATE_RESULTS_DATA", data);
  },
  updateDataForResultViewHeaders(
    { commit },
    { associatedHeaders, notAssociatedHeaders }
  ) {
    commit("UPDATE_HEADERS_DATA", { associatedHeaders, notAssociatedHeaders });
  },
  clearDataForResultView({ commit }) {
    commit("CLEAR_RESULTS_DATA");
  },
  clearDataForResultViewHeaders({ commit }) {
    commit("CLEAR_HEADERS_DATA");
  },
  openDialog({ commit }) {
    commit("OPEN_DIALOG");
  },
  closeDialog({ commit }) {
    commit("CLOSE_DIALOG");
  }
};

const mutations = {
  SET_ALL_IN_SITU_STATIONS(state, data) {
    state.inSituStations = data;
  },
  IN_SITU_STATIONS_LOADED(state, value) {
    state.inSituStationsLoaded = value;
  },
  SET_SELECTED_IN_SITU_STATION(state, data) {
    state.selectedInSituStation = data;
  },
  SET_ASSOCIATE_POP_UP_STATUS(state, status) {
    state.associatePopUp = status;
  },
  UPDATE_RESULTS_DATA(state, payload) {
    state.dataForResultView.push(payload);
  },
  UPDATE_HEADERS_DATA(state, payload) {
    state.dataForResultViewHeaders.associatedHeaders =
      payload.associatedHeaders;
    state.dataForResultViewHeaders.notAssociatedHeaders =
      payload.notAssociatedHeaders;
  },
  CLEAR_RESULTS_DATA(state) {
    state.dataForResultView = [];
  },
  CLEAR_HEADERS_DATA(state) {
    state.dataForResultViewHeaders = [];
  },
  OPEN_DIALOG(state) {
    state.uploadInSituDataDialogVisible = true;
  },
  CLOSE_DIALOG(state) {
    state.uploadInSituDataDialogVisible = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
