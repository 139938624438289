const state = {
  showPlotFeature: false,
  selectedStation: null,
  lineStation: null,
  dataCache: [],
  showTransectPlot: false,
  showPlot: false,
  showFullSizePlot: false,
  previewPlotShowAll: false
};

const getters = {
  entriesDataCacheFilled(state) {
    return state.dataCache.length > 0;
  },
  disabledPlotButton(state) {
    return !state.dataCache.length;
  },
  dataCachePlotIds(state) {
    return state.dataCache.map(elem => elem.plotId);
  }
};

const actions = {
  setShowPlot({ commit }, value) {
    commit("SHOW_PLOT", value);
  },
  addDataEntry({ commit }, plotData) {
    commit("ADD_DATA_ENTRY", plotData);
  },
  resetDataCache({ commit }) {
    commit("RESET_DATA_CACHE");
  },
  deleteDataEntry({ commit }, ident) {
    commit("DELETE_PLOT_ENTRY", ident);
  },
  previewPlotShowAll({ commit }, value) {
    commit("PREVIEW_PLOT_SHOW_ALL", value);
  },
  setStation({ commit }, value) {
    commit("SET_STATION", value);
  },
  setShowTransectPlot({ commit }, value) {
    commit("SET_SHOW_TRANSECT_PLOT", value);
  },
  setLineStation({ commit }, value) {
    commit("SET_LINE_STATION", value);
  },
  setShowPlotFeature({ commit }, value) {
    commit("SET_SHOW_PLOT_FEATURE", value);
  },
  setShowFullSizePlot({ commit }, value) {
    commit("SET_SHOW_FULL_SIZE_PLOT", value);
  }
};

const mutations = {
  SHOW_PLOT(state, value) {
    state.showPlot = value;
  },
  RESET_DATA_CACHE(state) {
    state.showPlot = false;
    state.dataCache = [];
  },
  ADD_DATA_ENTRY(state, plotData) {
    state.dataCache.push(plotData);
  },
  DELETE_PLOT_ENTRY(state, ident) {
    state.dataCache = state.dataCache.filter(data => data.ident != ident);
  },
  PREVIEW_PLOT_SHOW_ALL(state, value) {
    state.previewPlotShowAll = value;
  },
  SET_STATION(state, value) {
    state.selectedStation = value;
  },
  SET_SHOW_TRANSECT_PLOT(state, value) {
    state.showTransectPlot = value;
  },
  SET_LINE_STATION(state, value) {
    state.lineStation = value;
  },
  SET_SHOW_PLOT_FEATURE(state, value) {
    state.showPlotFeature = value;
  },
  SET_SHOW_FULL_SIZE_PLOT(state, value) {
    state.showFullSizePlot = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
