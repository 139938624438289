<template>
  <div>
    <v-col
      cols="12"
      class="px-0"
      v-for="region in regionsList"
      :key="region.id"
    >
      <region-list-card
        :region="region"
        @loadingFinish="mapLoading = false"
      ></region-list-card>
    </v-col>
    <v-overlay
      v-model="mapLoading"
      class="align-center justify-center"
      contained
    >
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import RegionListCard from "@/core/components/regionsOverview/regionListCard.vue";

export default {
  name: "RegionsList",
  components: { RegionListCard },
  data: () => ({
    mapLoading: true,
    regionsList: []
  }),
  props: {
    regionFeatures: Array,
    search: String
  },
  computed: {},
  methods: {},
  watch: {
    search() {
      let regions = this.regionFeatures;
      if (this.search?.length) {
        regions = regions.filter(region =>
          region.properties.name
            .toLowerCase()
            .includes(this.search.toLowerCase())
        );
      }
      this.regionsList = regions;
    },
    regionFeatures() {
      this.regionsList = this.regionFeatures;
    }
  },
  mounted() {
    this.regionsList = this.regionFeatures;
  }
};
</script>

<style scoped></style>
