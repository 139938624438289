<template>
  <v-col class="text-right pt-0 pr-0" cols="auto">
    <v-text-field
      v-if="searchBar"
      append-icon="mdi-magnify"
      :label="$t('regionOverview.searchRegion')"
      dense
      outlined
      hide-details
      autofocus
      v-model="search"
      @click:append="closeSearchBar"
      class="rounded-search-bar"
    >
    </v-text-field>
    <v-btn v-if="!searchBar" text icon @click="searchBar = !searchBar">
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
  </v-col>
</template>
<script>
export default {
  name: "SearchBar",
  data: () => ({
    searchBar: false,
    search: ""
  }),
  methods: {
    closeSearchBar() {
      this.searchBar = false;
      this.search = "";
    }
  },
  watch: {
    search() {
      this.$emit("filterRegions", this.search);
    }
  }
};
</script>

<style scoped>
.rounded-search-bar {
  border-radius: 25px;
  width: 100%;
  align-self: end;
  text-align: end;
}
</style>
