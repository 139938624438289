import axios from "axios";
import Vue from "vue";

const state = {
  vectorLayer: [],
  vectorLayerLoaded: false,
  activeVectorLayer: {
    layerId: 0,
    layerName: "",
    parameterId: "",
    parameterName: "",
    startDate: "",
    endDate: "",
    unit: "",
    shpType: "",
    timestep: "",
    style: {}
  }
};

const getters = {
  inSituLayer(state) {
    return state.vectorLayer.filter(
      item => item.name !== "Analysis layer" && item.owner === true
    );
  }
};

const actions = {
  async fetchVectorLayer({ commit }, region_id) {
    commit("VECTOR_LAYER_LOADED", false);
    try {
      const client = Vue.prototype.$appConfig.keycloakClient;
      const response = await axios.get(
        `/vector/${client}/region/${region_id}/layers`
      );
      commit("SET_VECTOR_LAYER", response.data);
    } catch (err) {
      console.log(err);
    }
    commit("VECTOR_LAYER_LOADED", true);
  },
  setActiveVectorLayer(
    { commit },
    {
      layerId,
      layerName,
      parameterId,
      parameterName,
      startDate,
      endDate,
      unit,
      shpType,
      timestep,
      style
    }
  ) {
    commit("SET_ACTIVE_VECTOR_LAYER", {
      layerId,
      layerName,
      parameterId,
      parameterName,
      startDate,
      endDate,
      unit,
      shpType,
      timestep,
      style
    });
  }
};

const mutations = {
  SET_VECTOR_LAYER(state, data) {
    state.vectorLayer = data;
  },
  VECTOR_LAYER_LOADED(state, value) {
    state.vectorLayerLoaded = value;
  },
  SET_ACTIVE_VECTOR_LAYER(
    state,
    {
      layerId,
      layerName,
      parameterId,
      parameterName,
      startDate,
      endDate,
      unit,
      shpType,
      timestep,
      style
    }
  ) {
    state.activeVectorLayer = {
      layerId,
      layerName,
      parameterId,
      parameterName,
      startDate,
      endDate,
      unit,
      shpType,
      timestep,
      style
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
