const state = {
  showMenu: null,
  visualizeMapLoaded: false,
  snackDialog: false,
  snackColor: "success",
  snackMessage: "",
  snackTimeout: 5000
};

const actions = {
  showMenu({ commit }, value) {
    commit("SHOW_MENU", value);
  },
  setVisMapLoaded({ commit }, value) {
    commit("SET_VIS_MAP_LOADED", value);
  },
  showSnackbar({ commit }, { show, message, color, timeout }) {
    commit("SHOW_SNACKBAR", { show, message, color, timeout });
  },
  hideSnackbar({ commit }) {
    commit("HIDE_SNACKBAR");
  }
};

const mutations = {
  SHOW_MENU(state, value) {
    state.showMenu = value;
  },
  SET_VIS_MAP_LOADED(state, value) {
    state.visualizeMapLoaded = value;
  },
  SHOW_SNACKBAR(state, { show, message, color, timeout = 5000 }) {
    state.snackMessage = message;
    state.snackColor = color;
    state.snackTimeout = timeout;
    state.snackDialog = show;
  },
  HIDE_SNACKBAR(state) {
    state.snackDialog = false;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
