<template>
  <v-expansion-panels focusable class="pa-2">
    <v-expansion-panel
      class="mb-2"
      v-for="(faq, index) in translatedFAQ"
      :key="index"
    >
      <v-expansion-panel-header>{{ faq.question }}</v-expansion-panel-header>
      <v-expansion-panel-content class="pt-2">{{ faq.answer }}</v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import faqs from "@/core/assets/suportCenter/faqs.json";

export default {
  data() {
    return {
      FAQs: faqs
    };
  },
  computed: {
    translatedFAQ() {
      return this.FAQs.map(faq => {
        const questionKey = `question_${this.$i18n.locale}`;
        const answerKey = `answer_${this.$i18n.locale}`;
        return {
          question: faq[questionKey],
          answer: faq[answerKey]
        };
      });
    }
  }
};
</script>
