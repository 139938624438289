<template>
  <v-list dense nav style="background-color: transparent">
    <v-list-item
      @click="emitChangeView('GettingStarted')"
      class="pa-0"
      :class="{ 'highlight-class': selectedItem === 'GettingStarted' }"
    >
      <v-tooltip v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="pr-1" v-bind="attrs" v-on="on"
            >mdi-play-circle-outline</v-icon
          >
        </template>
        {{ $t("supportCenter.gettingStarted") }}
      </v-tooltip>
      <v-icon v-else class="pr-1">mdi-play-circle-outline</v-icon>
      <v-list-item-content
        v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
        >{{ $t("supportCenter.gettingStarted") }}</v-list-item-content
      >
    </v-list-item>
    <v-list-item
      @click="emitChangeView('FAQComponent')"
      class="pa-0"
      :class="{ 'highlight-class': selectedItem === 'FAQComponent' }"
    >
      <v-tooltip v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="pr-1" v-bind="attrs" v-on="on"
            >mdi-comment-question-outline</v-icon
          >
        </template>
        {{ $t("supportCenter.faqs") }}
      </v-tooltip>
      <v-icon v-else class="pr-1">mdi-comment-question-outline</v-icon>
      <v-list-item-content
        v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
        >{{ $t("supportCenter.faqs") }}</v-list-item-content
      >
    </v-list-item>
    <v-list-item
      @click="emitChangeView('UserManualComponent')"
      :class="{ 'highlight-class': selectedItem === 'UserManualComponent' }"
      class="pa-0 mb-16"
    >
      <v-tooltip v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="pr-1" v-bind="attrs" v-on="on"
            >mdi-book-open-page-variant-outline</v-icon
          >
        </template>
        {{ $t("supportCenter.userManual") }}
      </v-tooltip>
      <v-icon v-else class="pr-1">mdi-book-open-page-variant-outline</v-icon>
      <v-list-item-content
        v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
        >{{ $t("supportCenter.userManual") }}</v-list-item-content
      >
    </v-list-item>
    <v-list-item
      @click="emitChangeView('SubscriptionTable')"
      class="pa-0 mb-16"
      :class="{ 'highlight-class': selectedItem === 'SubscriptionTable' }"
    >
      <v-tooltip v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="pr-1" v-bind="attrs" v-on="on"
            >mdi-view-dashboard-outline</v-icon
          >
        </template>
        {{ $t("supportCenter.subscriptionPlans") }}
      </v-tooltip>
      <v-icon v-else class="pr-1">mdi-view-dashboard-outline</v-icon>
      <v-list-item-content
        v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
        >{{ $t("supportCenter.subscriptionPlans") }}</v-list-item-content
      >
    </v-list-item>
    <v-list-item
      @click="emitChangeView('ContactForm')"
      class="pa-0"
      :class="{ 'highlight-class': selectedItem === 'ContactForm' }"
    >
      <v-tooltip v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="pr-1" v-bind="attrs" v-on="on"
            >mdi-chat-outline</v-icon
          >
        </template>
        {{ $t("supportCenter.contactSupport") }}
      </v-tooltip>
      <v-icon v-else class="pr-1">mdi-chat-outline</v-icon>
      <v-list-item-content
        v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
        >{{ $t("supportCenter.contactSupport") }}</v-list-item-content
      >
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      isHovered: false,
      selectedItem: null
    };
  },
  methods: {
    emitChangeView(viewName) {
      this.$emit("change-view", viewName);
      this.selectedItem = viewName;
    }
  }
};
</script>
<style>
.highlight-class {
  background-color: #f0f0f0;
}
</style>
