<template>
  <v-dialog
    v-model="dialog"
    width="auto"
    max-width="455"
    style="z-index: 999"
    v-if="adminRole || regionOwner"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="div-hover">
        <v-icon v-if="place === ''" class="mb-1" color="grey">
          mdi-delete-forever
        </v-icon>
        <div v-else>
          <span
            class="icon-symbol material-icons-outlined justify-center py-1"
            v-bind="attrs"
            v-on="on"
          >
            delete_forever
          </span>
          <!--        <v-icon large class="justify-center" -->
          <!--          >mdi-delete-forever</v-icon-->
          <!--        >-->
          <br />
          <span class="icon-text">{{
            $t(`${"regionOverview.deleteRegion"}`)
          }}</span>
        </div>
      </div>
    </template>
    <v-card class="mx-auto">
      <v-card
        elevation="0"
        class="white--text align-end"
        height="120px"
        style="background-color: #FFDBDE"
      >
        <v-btn class="icon-check" style="background-color: #FF495C" icon fab>
          <v-icon color="white" large>mdi-alert-outline</v-icon>
        </v-btn>
      </v-card>
      <v-card-title class="pt-8 d-flex justify-center align-center"
        ><span>
          {{ $t("deleteDialog.sureYouWantToDelete") }}
          <span
            style="color: #1D4180"
            class="pl-2 d-flex justify-center align-center"
            >{{ region.properties.name }}?</span
          >
        </span>
      </v-card-title>

      <v-card-text class="pt-3 text-center align-center">
        {{ $t("deleteDialog.cannotBeUndone") }}<br />
        {{ $t("deleteDialog.permanentlyDelete") }}
        <br />
        <br />
        <span v-if="adminRole && !regionOwner" style="color: red">
          {{ $t("deleteDialog.beAwareAdminRights") }}</span
        >
        <v-row class="align-center">
          <v-col class="d-flex justify-center">
            <v-checkbox
              :label="$t('deleteDialog.iUnderstandThis')"
              v-model="confirmDelete"
            >
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          :disabled="!confirmDelete"
          color="primary"
          @click="deleteRegion()"
        >
          {{ $t("deleteDialog.delete") }}
        </v-btn>
        <v-dialog v-model="successDialog" persistent width="450">
          <v-card class="mx-auto">
            <v-card
              elevation="0"
              class="white--text align-end"
              height="120px"
              style="background-color: #d7f3e1"
            >
              <v-btn
                class="icon-check"
                style="background-color: #35C168"
                icon
                fab
              >
                <v-icon color="white" large>mdi-check</v-icon>
              </v-btn>
            </v-card>
            <v-card-title class="pt-8 justify-center">{{
              $t("deleteDialog.done")
            }}</v-card-title>

            <v-card-text class="pt-3 text-center">
              {{ $t("deleteDialog.regionSuccessfullyDeleted") }}
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn dark rounded elevation="0" @click="reloadRegions()">
                {{ $t("close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import Vue from "vue";

export default {
  name: "ConfirmRegionDelete",
  props: {
    region: {
      type: Object,
    },
    place: { type: String, default: () => "" },
  },
  data: function() {
    return {
      dialog: false,
      successDialog: false,
      confirmDelete: false,
    };
  },
  computed: {
    adminRole() {
      const client = Vue.prototype.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles;
      if (roles) {
        const hasEomapOrAdminRole = roles.includes("admin");
        return hasEomapOrAdminRole;
      }
      return false;
    },
    regionOwner() {
      const hasOwner = this.region.properties.owner;
      return hasOwner;
    },
  },
  methods: {
    ...mapActions("management", ["fetchRegions"]),
    deleteRegion() {
      const client = this.$appConfig.keycloakClient;
      axios
        .delete(`/management/${client}/regions/${this.region.id}`)
        .then(() => {
          this.confirmDelete = false;
          this.dialog = false;
          this.successDialog = true;
        });
    },
    async reloadRegions() {
      this.successDialog = false;
      await this.fetchRegions();
    },
  },
  watch: {},
};
</script>

<style scoped>
#close-btn {
  z-index: 1;
}
.icon-check {
  position: absolute;
  top: 90px;
  left: 195px;
}

.icon-text {
  /* v-text/body-small */
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.icon-symbol {
  font-size: 38px;
}

.div-hover:hover {
  background-color: #f5f5f5;
  border-radius: 5px;
}
</style>
