<template>
  <section>
    <v-container>
      <v-carousel v-model="tab" show-arrows hide-delimiters cycle>
        <v-carousel-item v-for="item in carouselItems" :key="item.title">
          <v-sheet color="#f0f2f4" height="100%" tile>
            <v-row class="mx-16 fill-height align-center">
              <v-col cols="6" v-if="!xs">
                <v-img :src="item.imageUrl"></v-img>
              </v-col>
              <v-col
                class="text-center d-flex flex-column"
                style="overflow-wrap: break-word"
              >
                <div class="text-h4" style="color: black">
                  {{ $t(item.title) }}
                </div>
                <div
                  class="text-h6 font-weight-light mt-4"
                  style="color: black"
                >
                  {{ $t(item.text) }}
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "InfoSection",
  props: {
    client: {},
    contentInfos: {}
  },
  data() {
    return {
      xs: {},
      tab: 0
    };
  },
  computed: {
    carouselItems() {
      return this.contentInfos.infoSection;
    }
  },
  created() {
    this.xs = this.$vuetify.breakpoint.xs;
  }
};
</script>

<style scoped>
section {
  padding: 82px 0;
  background-color: #f0f2f4;
}
</style>
