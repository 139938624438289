import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

const MapboxConfig = {
  accessToken:
    "pk.eyJ1IjoiZW9tYXAiLCJhIjoiY2tkZnhvaDJiMjAzeTM2bWh3cHlpN3o0NyJ9.f24MRfc5um4GmKiQC5bCoQ",
  style: "mapbox://styles/eomap/ckq7xvq6s068c17qwcjbmc7wx",
  center: [37, -25]
};

export default MapboxConfig;
