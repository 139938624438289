import Vue from "vue";
import store from "@/core/store";

export const routeGuard = async (next, to) => {
  const client = Vue.prototype.$appConfig.keycloakClient;
  const roles = Vue.prototype.$keycloak.resourceAccess?.[client]?.roles || [];
  const hasEomapRole = Vue.prototype.$keycloak.realmAccess?.roles.includes(
    "eomap"
  );
  const region = await store.dispatch(
    "management/getOrFetchRegion",
    to.params.regionId
  );
  let renameMonitoring = "";
  if (to.meta.service === "monitoring") {
    renameMonitoring = "dashboard";
  } else renameMonitoring = to.meta.service;
  if (hasEomapRole || roles.includes(renameMonitoring)) {
    next();
  } else if (
    region.properties.services &&
    region.properties?.services.includes("premium")
  ) {
    next();
  } else {
    next({ name: "Welcome" });
  }
};

export const routeGuardAdmin = async (next, to) => {
  const client = Vue.prototype.$appConfig.keycloakClient;
  const roles = Vue.prototype.$keycloak.resourceAccess?.[client]?.roles || [];
  const hasEomapRole = Vue.prototype.$keycloak.realmAccess?.roles.includes(
    "eomap"
  );
  const region = await store.dispatch(
    "management/getOrFetchRegion",
    to.params.regionId
  );
  if (roles.includes("admin")) {
    next();
  } else if (hasEomapRole && region.properties.owner) {
    next();
  } else {
    next({ name: "Welcome" });
  }
};
