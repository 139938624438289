const state = {
  selectedStations: [],
  selectedStation: null,
  product: {},
  syncMonth: null,
  monthsDict: [
    { val: "01", label: "month.jan" },
    { val: "02", label: "month.feb" },
    { val: "03", label: "month.mar" },
    { val: "04", label: "month.apr" },
    { val: "05", label: "month.mai" },
    { val: "06", label: "month.jun" },
    { val: "07", label: "month.jul" },
    { val: "08", label: "month.aug" },
    { val: "09", label: "month.sep" },
    { val: "10", label: "month.oct" },
    { val: "11", label: "month.nov" },
    { val: "12", label: "month.dec" }
  ],
  color1: "#1D4180",
  color2: "#40ADD5",
  compareColors: ["#40ADD5", "#4B4B4D"]
};

const getters = {
  getStations(state) {
    return state.selectedStations;
  },
  getStation(state) {
    return state.selectedStation;
  },
  getProduct(state) {
    return state.product;
  }
};

const actions = {
  updateSelectedStations({ commit }, stations) {
    commit("UPDATE_SELECTED_STATIONS", stations);
  },
  setSelectedStations({ commit }, payload) {
    commit("SET_SELECTED_STATIONS", payload);
  },
  setSyncMonth({ commit }, month) {
    commit("SET_SYNC_MONTH", month);
  },
  updateSelectedStation({ commit }, product) {
    commit("UPDATE_SELECTED_STATION", product);
  },
  updateProduct({ commit }, product) {
    commit("UPDATE_PRODUCT", product);
  }
};

const mutations = {
  UPDATE_SELECTED_STATIONS(state, station) {
    if (state.selectedStations.includes(station)) {
      const index = state.selectedStations.indexOf(station);
      state.selectedStations.splice(index, 1);
    } else {
      state.selectedStations.push(station);
    }
  },
  SET_SELECTED_STATIONS(state, payload) {
    if (payload.index == 0 || payload.index == 1) {
      state.selectedStations[payload.index] = null;
      state.selectedStations[payload.index] = payload.station;
    } else {
      state.selectedStations = payload;
    }
  },
  SET_SYNC_MONTH(state, month) {
    state.syncMonth = month;
  },
  UPDATE_SELECTED_STATION(state, data) {
    state.selectedStation = data;
  },
  UPDATE_PRODUCT(state, data) {
    state.product = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
