<template>
  <v-container>
    <v-row no-gutters class="pb-1">{{ $t("supportCenter.LetsBegin") }}</v-row>
    <div>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="4"
          v-for="(video, index) in translatedVideoTitles"
          :key="index"
        >
          <v-card>
            <v-responsive>
              <video controls :src="video.link"></video>
            </v-responsive>
          </v-card>
          <v-card-title class="font-weight-bold" style="word-break: keep-all">
            {{ video.title }}</v-card-title
          >
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import supportCenterInfos from "@/core/assets/suportCenter/supportCenterInfos.json";

export default {
  data() {
    return {
      tutorialVideos: supportCenterInfos.gettingStartedTutorialVideos
    };
  },
  computed: {
    translatedVideoTitles() {
      return this.tutorialVideos.map(video => {
        const titleKey = `title_${this.$i18n.locale.toUpperCase()}`;
        return {
          title: video[titleKey],
          link: video.link
        };
      });
    }
  }
};
</script>

<style scoped>
video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
</style>
