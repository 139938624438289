<template>
  <section>
    <v-container v-if="info">
      <v-row class="text-center mb-12">
        <v-col class="text-h4">{{ $t(info.heading) }}</v-col>
      </v-row>
      <v-row class="text-center justify-center">
        <v-col
          md="4"
          sm="6"
          cols="12"
          v-for="item in info.icons"
          :key="item.text"
          class="d-flex justify-center align-center flex-column pa-6"
        >
          <v-img
            contain
            v-if="item.imageUrl.split('.').pop() === 'png'"
            :src="item.imageUrl"
            max-width="120"
          ></v-img>
          <v-icon v-else size="100" color="primary">{{ item.imageUrl }}</v-icon>
          <div
            class="mt-2 text-h6 font-weight-light"
            style="height: 50px"
            v-html="$t(item.text)"
          ></div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  name: "UniqueServicesSection",
  props: {
    client: {},
    contentInfos: {}
  },
  computed: {
    info() {
      return this.contentInfos.uniqueServicesSection;
    }
  }
};
</script>

<style scoped>
section {
  padding: 82px 0;
  background-color: white;
}
</style>
