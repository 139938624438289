import { mapState } from "vuex";
import Vue from "vue";

let creditMixin = {
  methods: {
    async fetchUserAccounts() {
      const response = await this.$credit.get(`/main/accounts`, {
        headers: {
          Authorization: `Bearer ${Vue.prototype.$keycloak.token}`
        }
      });
      return response.data;
    },
    async requestPossibleAccounts(amount) {
      const response = await this.$credit.get(
        `/main/accounts?transaction_amount=-${amount}`,
        {
          headers: {
            Authorization: `Bearer ${Vue.prototype.$keycloak.token}`
          }
        }
      );
      return response.data;
    },
    async fetchAccountTransactions(accuntId, client) {
      const response = await this.$credit.get(
        `/main/transactions?account_id=${accuntId}&client=${client}`,
        {
          headers: {
            Authorization: `Bearer ${Vue.prototype.$keycloak.token}`
          }
        }
      );
      return response.data;
    },
    async fetchClientInformation() {
      try {
        const response = await this.$credit.get(
          "management/client-information",
          {
            headers: {
              Authorization: `Bearer ${Vue.prototype.$keycloak.token}`
            }
          }
        );
        return response.data;
      } catch (e) {
        return undefined;
      }
    },
    async setClientInformation(clientInfo) {
      if (clientInfo.id) {
        await this.$credit.put(`management/client-information`, clientInfo, {
          headers: {
            Authorization: `Bearer ${Vue.prototype.$keycloak.token}`
          }
        });
      } else {
        await this.$credit.post("management/client-information", clientInfo, {
          headers: {
            Authorization: `Bearer ${Vue.prototype.$keycloak.token}`
          }
        });
      }
    },
    async postQuote(amount) {
      await this.$credit.post(
        "main/credit-quotes",
        { client: this.$appConfig.keycloakClient, amount: amount },
        {
          headers: {
            Authorization: `Bearer ${Vue.prototype.$keycloak.token}`
          }
        }
      );
    },
    async postMessage(email, client_id, topic, content) {
      const url = `contact/contact-form`;
      const response = await this.$credit.post(
        url,
        {
          email: email,
          client_id: client_id,
          topic: topic,
          content: content
        },

        {
          headers: {
            Authorization: `Bearer ${Vue.prototype.$keycloak.token}`
          }
        }
      );
      return response.data;
    }
  },
  computed: {
    ...mapState("order", ["price"])
  }
};

export default creditMixin;
