const state = {
  center: { lat: 0, lng: 0 },
  availableProducts: [],
  layersWithRegionThreshold: [],
  firstSceneDate: null,
  selectedStartDate: null,
  selectedEndDate: null,
  allData: false,
  regionThresholdItems: []
};
const getters = {
  getCenter(state) {
    return state.center;
  }
};
const actions = {
  changeCenter({ commit }, value) {
    commit("CHANGE_CENTER", value);
  },
  setAvailableProducts({ commit }, products) {
    commit("SET_AVAILABLE_PRODUCTS", products);
  },
  setLayersWithRegionThreshold({ commit }, data) {
    commit("SET_LAYERS_WITH_REGION_THRESHOLD", data);
  },
  setFirstSceneDate({ commit }, firstSceneDate) {
    commit("SET_FIRST_SCENE_DATE", firstSceneDate);
  },
  setAllData({ commit }, allDataStatus) {
    commit("SET_ALL_DATA", allDataStatus);
  },
  setSelectedStartDate({ commit }, startDate) {
    commit("SET_SELECTED_START_DATE", startDate);
  },
  setSelectedEndDate({ commit }, endDate) {
    commit("SET_SELECTED_END_DATE", endDate);
  },
  setThresholdItems({ commit }, items) {
    commit("SET_THRESHOLD_ITEMS", items);
  }
};
const mutations = {
  CHANGE_CENTER(state, value) {
    state.center = value;
  },
  SET_AVAILABLE_PRODUCTS(state, products) {
    state.availableProducts = products;
  },
  SET_LAYERS_WITH_REGION_THRESHOLD(state, data) {
    state.layersWithRegionThreshold = data;
  },
  SET_FIRST_SCENE_DATE(state, firstSceneDate) {
    state.firstSceneDate = firstSceneDate;
  },
  SET_ALL_DATA(state, allDataStatus) {
    state.allData = allDataStatus;
  },
  SET_SELECTED_START_DATE(state, startDate) {
    state.selectedStartDate = startDate;
  },
  SET_SELECTED_END_DATE(state, endDate) {
    state.selectedEndDate = endDate;
  },
  SET_THRESHOLD_ITEMS(state, items) {
    state.regionThresholdItems = items;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
