<template>
  <section>
    <v-container fluid class="video-container">
      <video autoplay muted loop>
        <source :src="link" type="video/mp4" />
      </video>
      <div class="video-overlay" :style="{ top: -height + 'px' }">
        <div class="video-overlay-center">
          <div :class="titleClasses">
            {{ $t(title) }}
          </div>
          <div :class="subtitleClasses">
            {{ $t(subTitle) }}
          </div>
          <p>
            <v-btn
              v-if="$appConfig.keycloakClient !== 'xylem'"
              outlined
              class="mt-12 mr-5"
              x-large
              color="white"
              @click="$keycloak.login()"
            >
              {{ $t("welcomePage.loginBtn") }}
            </v-btn>
            <v-btn
              v-if="
                $appConfig.keycloakClient !== 'coasts' &&
                  $appConfig.keycloakClient !== 'xylem'
              "
              outlined
              x-large
              color="white"
              class="mt-12 ml-5"
              @click="$keycloak.register()"
            >
              {{ $t("welcomePage.registerBtn") }}
            </v-btn>
            <v-btn
              v-if="$appConfig.keycloakClient === 'xylem'"
              outlined
              x-large
              color="white"
              class="mt-12 ml-5"
              @click="$keycloak.register()"
            >
              {{ $t("welcomePage.registerFreeBtn") }}
            </v-btn>
          </p>
        </div>
        <div class="bottom-line">
          <v-row class="justify-space-between">
            <v-img
              contain
              class="ml-5"
              max-width="100px"
              height="50px"
              alt="EOMAP Logo"
            ></v-img>
            <v-btn
              text
              color="white"
              size="large"
              @click="$emit('scroll-down')"
            >
              <template v-slot:default>
                <div
                  v-if="$appConfig.keycloakClient !== 'coasts'"
                  class="d-flex flex-column align-center"
                >
                  {{ $t("welcomePage.findOutMore") }}
                  <v-icon size="32px">mdi-menu-down</v-icon>
                </div>
              </template>
            </v-btn>
            <v-img
              contain
              class="mr-5"
              max-width="130px"
              height="50px"
              :src="logo"
              alt="EOMAP Logo"
            ></v-img>
          </v-row>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "VideoHeaderSection",
  data() {
    return {
      xs: {},
      height: null,
      portal_name: this.$appConfig.appName,
      logo: this.$appConfig.company
    };
  },
  props: {
    client: {},
    contentInfos: {}
  },
  computed: {
    eoappClasses() {
      return {
        "text-h3": !this.xs,
        "text-h4": this.xs,
        "font-weight-medium": !this.xs
      };
    },
    titleClasses() {
      return {
        "text-h1": !this.xs,
        "text-h2": this.xs,
        "font-weight-medium": !this.x
      };
    },
    subtitleClasses() {
      return {
        "text-h3": !this.xs,
        "text-h5": this.xs,
        "mt-6": !this.xs,
        "mt-4": this.xs
      };
    },
    title() {
      return this.$appConfig.appName;
    },
    subTitle() {
      return this.contentInfos.videoHeaderSection.subTitle;
    },
    link() {
      return this.contentInfos.videoHeaderSection.link;
    }
  },
  created() {
    this.xs = this.$vuetify.breakpoint.xs;
    this.height = window.innerHeight;
  }
};
</script>

<style>
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(75%);
}

.video-container {
  padding: 0;
  margin: 0;
  height: calc(100vh - 65px);
}

.video-overlay-center {
  position: absolute;
  top: 0;
  width: 100%;
  margin: 0 auto;
  padding-top: 30vh;
  color: white;
  text-align: center;
  text-shadow: 0 4px 9px rgba(0, 0, 0, 0.49);
  z-index: 2;
}

.video-overlay {
  position: relative;
  height: 100vh;
  width: 100%;
  z-index: 1;
}

.bottom-line {
  position: absolute;
  bottom: 24px;
  width: 100%;
  color: white;
  text-align: center;
}
</style>
